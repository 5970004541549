import React, { useCallback, useContext, useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormControl, Grid, makeStyles } from "@material-ui/core"
import CartContext from "../../Context/CartContext"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import ajax from "../../Helpers/ajax"
import { useFetch } from "../../hooks/useFetch"
import { useForm } from "../../hooks/useForm"
import { PayPalButton } from "react-paypal-button-v2"
import config from "../../Config"
import { validateBillingForm } from "../../Helpers/validation"
import { StoreContext } from "../../Context/StoreContext"
import { priceFormat } from "../../Helpers/store"
import { findAllByDisplayValue } from "@testing-library/react"
import { Loading } from "../Loading/Loading"
import { useSnackbar } from "notistack"
import { CountryField } from "../countryField"

const useStyle = makeStyles({
  boxPaypal: {
    width: 400,
    "& div": {
      width: "100%",
    },
  },
  boxContent: {
    "& .MuiFormControl-marginDense": {
      marginTop: 3,
    },
  },
})

export const BillingFormDialog = ({ open, setOpen, payment }) => {
  const classes = useStyle()
  const { enqueueSnackbar } = useSnackbar()
  const { cart } = useContext(CartContext)
  const { store_name, store_id, store_url, config_currency } =
    useContext(StoreContext)
  const total = cart.getTotals()
  const discount = priceFormat(cart.getDiscount())

  const { formValues } = useContext(CheckoutFormContext)
  const { formValues: values, handleInputChange } = useForm({
    name: "",
    email: "",
    address: "",
    country: "",
    city: "",
  })

  const { name, email, address, country, city } = values
  const [orderId, setOrderId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const fetchData = async (url, initial) => {
    const response = await fetch(url, initial)
    const data = await response.json()

    return data
  }

  useEffect(() => {}, [orderId])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async () => {
    const billingForm = {
      ...values,
      store_id,
      store_name,
      store_url,
      customer_group_id: config.store.customer_group_id,
      payment_method: payment,
    }

    const errors = validateBillingForm(billingForm)

    if (Object.entries(errors).length !== 0) {
      setErrors(errors)
      return
    }

    const { url, initial } = ajax.senData(
      JSON.stringify({
        ...formValues,
        cart: {
          ...cart,
          coupon: { ...cart.coupon, value: discount },
          total,
        },
        billingForm,
      })
    )
    setLoading(true)
    const response = await fetchData(url, initial)
    if (response.success) {
      setOrderId(response.data.order_id)
    } else {
      enqueueSnackbar("error in the payment method", { variant: "error" })
      //alert("error in the payment method")
    }
    setLoading(false)
  }

  const dialogContent = () => (
    <>
      {!loading ? (
        <>
          <DialogContentText color="textPrimary">
            Renseigner les coordonnées du porteur de la carte
          </DialogContentText>
          <Grid container spacing={3} className={classes.boxContent}>
            <Grid item md={6}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nom"
                name="name"
                type="text"
                value={name}
                fullWidth
                onChange={handleInputChange}
                {...errors?.name}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                name="email"
                value={email}
                fullWidth
                onChange={handleInputChange}
                {...errors?.email}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                autoFocus
                margin="dense"
                id="address"
                label="Adresse postale"
                name="address"
                value={address}
                type="text"
                fullWidth
                onChange={handleInputChange}
                {...errors?.address}
              />
            </Grid>
            <Grid item md={6}>
              <CountryField
                country={country}
                handleChange={handleInputChange}
                errors={errors?.country}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="city"
                  label="Ville"
                  type="text"
                  name="city"
                  value={city}
                  fullWidth
                  onChange={handleInputChange}
                  {...errors?.city}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loading isHome={false} />
      )}
    </>
  )

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Informations de facturation
        </DialogTitle>
        <DialogContent>
          {!orderId ? (
            dialogContent()
          ) : !loading ? (
            <Grid container className={classes.boxPaypal}>
              <PayPalButton
                createOrder={async (d, actions) => {
                  const paypalOrder = actions.order.create({
                    purchase_units: [
                      {
                        description: `${store_name} order =  ${orderId}`,
                        custom_id: orderId,
                        invoice_id: orderId,
                        amount: {
                          currency_code: config_currency,
                          value: total,
                        },
                      },
                    ],
                    // application_context: {
                    //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                    // }
                  })
                  return paypalOrder
                }}
                onApprove={async (d, actions) => {
                  // Capture the funds from the transaction
                  return actions.order.capture().then(async (details) => {
                    // Show a success message to your buyer
                    enqueueSnackbar("Paiement effectué", {
                      variant: "success",
                    })
                    /*alert(
                      "Transaction completed by " +
                        details.payer.name.given_name
                    )*/
                    const paypal_order = d.orderID
                    const order_id = orderId
                    const transaction_id =
                      details?.purchase_units[0].payments?.captures[0].id
                    const { url, initial } = ajax.sendTransaction(
                      JSON.stringify({
                        id: order_id,
                        paypal_order: paypal_order,
                        transaction_id: transaction_id,
                      })
                    )
                    // OPTIONAL: Call your server to save the transaction
                    setLoading(true)
                    const response = await fetch(url, initial)
                    const json = await response.json()
                    json.success
                      ? window.location.replace("/thank-you")
                      : enqueueSnackbar(
                          "error could not save the information",
                          {
                            variant: "error",
                          }
                        )
                    return
                  })
                }}
                options={{
                  clientId: config.store.payment.paypal.client_id,
                  currency: config_currency,
                }}
              />
            </Grid>
          ) : (
            <Loading isHome={false} />
          )}
        </DialogContent>
        {!orderId && (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Payer
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}
