import React, { useContext, useCallback } from "react"
import { Grid, Typography, makeStyles, Paper } from "@material-ui/core"
import CartContext from "../../../Context/CartContext"
import { searchProduct, getOption, getFlight } from "../../../Helpers/products"
import ConfirmBaggageDialog from "./ConfirmBaggageDialog"
import { useState } from "react"
import { FreeBaggage } from "./FreeBaggage"
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage"
import config from "../../../Config"
import ProductsContext from "../../../Context/ProductsContext"

const useStyle = makeStyles((theme) => ({
  muiChip: {
    marginTop: "10px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "white",
    "& .MuiChip-label": {
      fontFamily: "modulus-pro-semi-bold",
    },
  },
  baggageContainer: {
    padding: "10px",
    width: "85%",
    margin: "10px 0",
    cursor: "pointer",
    border: "1px solid transparent",
  },
  active: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}))

const BaggageList = ({
  title,
  id,
  label,
  value,
  pos,
  handleMultipleOptionsPassenger,
  error,
}) => {
  const classes = useStyle()

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const [baggage, setBaggage] = useState(null)

  const addBaggage = (baggage, quantity) => {
    //handleInputChangePassenger({name:label, value:baggage}, pos)
    baggage.qty = quantity
    handleMultipleOptionsPassenger(
      `${label}`,
      { id: [baggage.option_value_id], val: baggage },
      pos
    )
    //handleCallback(baggage)
    cart.addExtraOption({ ...baggage, option: label, position: pos })
    setOpen(false)
  }

  const deleteBagage = (baggage) => {
    handleMultipleOptionsPassenger(
      `${label}`,
      { id: [baggage.option_value_id], val: baggage },
      pos
    )
    cart.deleteExtraOption({ ...baggage, option: label, position: pos })
    setOpen(false)
  }

  const { cart } = useContext(CartContext)
  const products = useContext(ProductsContext)

  let item = cart.getFirstItem()

  const extends_airline = item.options.extends_airline

  const nameOpt = config.store.product.options.baggage.name

  const product = searchProduct(products, item?.product_id)
  const options = getOption(product, nameOpt)
  const { option_id, product_option_id, type, option_value: baggages } = options
    ? options
    : {}

  const flight = extends_airline

  //const baggages = ['AJOUTER BAGAGE CABINE', 'AJOUTER BAGAGE SPECIAL', 'AJOUTER BAGAGE ANIMAL']

  const handleClick = (e, baggage) => {
    setOpen(true)
    setSelected(baggage)
    //document.querySelectorAll(`.${id}`).forEach(el => el.classList.remove(classes.active))
    //e.currentTarget.classList.add(classes.active)
  }
  return (
    <>
      {" "}
      {extends_airline && (
        <>
          <Grid item md={12}>
            <Typography variant="h4" style={{ paddingTop: 20 }}>
              {title}
            </Typography>
          </Grid>
          <FreeBaggage flight={flight} />
        </>
      )}
      {extends_airline &&
        baggages.map((baggage, index) => {
          const active = value[baggage.option_value_id] ? classes.active : ""
          baggage.option_id = option_id
          baggage.product_option_id = product_option_id
          baggage.type = type
          return (
            <Grid item md={4} xs={6} key={index}>
              <Paper
                elevation={1}
                onClick={(e) => handleClick(e, baggage)}
                className={`${classes.baggageContainer} ${id} ${active}`}
              >
                <Typography
                  variant="caption"
                  component="p"
                  style={{ fontSize: "10pt" }}
                >
                  {baggage.name.toUpperCase()}
                </Typography>
              </Paper>
            </Grid>
          )
        })}
      {selected && (
        <ConfirmBaggageDialog
          open={open}
          setOpen={setOpen}
          option={selected}
          setSelected={setSelected}
          value={value}
          addBaggage={addBaggage}
          deleteBagage={deleteBagage}
          service={nameOpt}
          label={label}
        />
      )}
      {extends_airline && <ErrorMessage error={error} />}
    </>
  )
}

export default React.memo(BaggageList)
