import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import CartContext from "../../Context/CartContext"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import { TrendingUpRounded } from "@material-ui/icons"
import DescriptionDialog from "../DescriptionDialog"
import { saveSession } from "../../Helpers/session"
import { AdditionalProduct } from "../AdditionalProduct"

const useStyle = makeStyles({
  paperContainer: {
    width: "100%",
    padding: "20px 10px 20px 20px",
  },
})

export const AdditionalProductList = ({ setStep, option }) => {
  const classes = useStyle()
  //const [selectedProduct, setSelectedProduct] = React.useState("")

  const { cart, email } = useContext(CartContext)
  const { formValues } = useContext(CheckoutFormContext)
  const { option_vol } = formValues

  const value = option_vol?.additionalProducts
    ? option_vol?.additionalProducts
    : {}
  const { option_value: products } = option

  let history = useHistory()

  const handleBack = () => {
    history.push("/checkout/step-2")
  }

  const handleNext = () => {
    saveSession(email, { formValues, cart })
    history.push("/checkout/step-end")
  }

  useEffect(() => {
    setStep(3)
  }, [])

  return (
    <Grid container item md={12}>
      <Paper elevation={3} className={classes.paperContainer}>
        <Grid item md={12}>
          <Typography variant="h4">
            Envie de compléter votre séjour ?
          </Typography>
        </Grid>
        <Grid container item md={12} spacing={3}>
          {products.map((product) => (
            <DescriptionDialog
              key={product.product_option_value_id}
              product={product}
              value={value}
              option={option}
            >
              <AdditionalProduct product={product} />
            </DescriptionDialog>
          ))}
        </Grid>
      </Paper>
      <Grid container item md={12} xs={12} style={{ margin: "20px 0" }}>
        <Grid item md={6} xs={6}>
          <Button
            variant="contained"
            onClick={() => handleBack()}
            disableElevation
          >
            RETOUR AUX OPTIONS
          </Button>
        </Grid>
        <Grid container item md={6} xs={6} justify="flex-end">
          <Button
            variant="contained"
            onClick={() => handleNext()}
            color="secondary"
            disableElevation
            endIcon={<PlayArrowIcon />}
          >
            Etape suivante
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
