import React from "react"
import { Paper, Typography, Grid, Button, makeStyles } from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"
import { useHistory } from "react-router-dom"
import { dateFormat } from "../../Helpers/dateFormat"
import { FlightDetails } from "./FlightDetails/FlightDetails"
import { RenderExtraOptions } from "./RenderExtraOptions/RenderExtraOptions"
import { priceFormat } from "../../Helpers/store"
import { RenderCoupon } from "./RenderCoupon/RenderCoupon"
import { onscroll, resizeBody } from "../../Helpers/utils"

const useStyle = makeStyles({
  containerGrid: {
    padding: 20,
  },
  containerCart: {
    maxHeight: "100%;",
    overflow: "scroll",
  },
  fixedTop: {
    position: "fixed",
    top: 5,
    width: 394.66,
  },
  containerProduct: {
    padding: "0 20px",
  },
  hrColor: {
    height: 1,
    border: 0,
    backgroundColor: "#eeeeee",
  },
  muiChip: {
    margin: "10px 0",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "white",
    "& .MuiChip-label": {
      fontFamily: "modulus-pro-semi-bold",
    },
  },
})

export const Cart = ({ cart }) => {
  const classes = useStyle()

  //const { cart } = useContext(CartContext)

  let history = useHistory()

  const itemProducts = cart.getItems()
  const quantity = cart.getTotalPersons()

  let extraOptions = cart.getExtraOptions()
  const coupon = cart.getCoupon()

  if (itemProducts.length === 0) {
    history.push("/")
  }

  const item = cart.getFirstItem()

  const handleClick = () => {
    //cart.reset()
    history.push({
      pathname: "/",
      hash: "edit",
      state: { product: item },
    })
  }

  window.onscroll = () => onscroll(classes, ".cart", 646)
  window.addEventListener("resize", () => resizeBody(classes, ".cart"))

  const getTotals = () => {
    return priceFormat(cart.getTotals(), "€")
  }

  const renderItems = (items) => {
    return items.map((item) => {
      const {
        product_id,
        name,
        category,
        price,
        special,
        daysPerNight,
        quantity,
        options,
      } = item
      const price_option = options.quantity * options.price

      let currentPriceFormated = priceFormat(price, "€")
      let priceTotal = priceFormat(
        price * daysPerNight * quantity + price_option,
        "€"
      )

      let detailedPrice =
        daysPerNight > 1
          ? `${currentPriceFormated} x ${daysPerNight} night x ${quantity}`
          : `${currentPriceFormated} x ${quantity}`

      let optionVol = ""
      if (options?.extends_airline) {
        optionVol += `${priceFormat(
          options?.extends_airline.price
        )} Inclus vol  x ${quantity}`
      }
      if (special !== 0) {
        const discountPriceFormated = priceFormat(special * daysPerNight, "€")
        const discountPrice = priceFormat(
          special * daysPerNight * quantity + price_option,
          "€"
        )
        currentPriceFormated = discountPriceFormated
        priceTotal = discountPrice
      }

      return (
        <React.Fragment key={product_id}>
          <Grid container className={classes.containerProduct}>
            <Grid item xs={6} md={7}>
              <Typography variant="h5">{name}</Typography>
              {category === "package" ? (
                <>
                  <Typography variant="caption">
                    {dateFormat(item.options.start)} au{" "}
                    {dateFormat(item.options.end)}
                    <br />
                    {item.quantity}{" "}
                    {item.quantity > 1 ? "personnes" : "personne"}
                  </Typography>
                  <br />

                  {item.options.extends_airline && (
                    <FlightDetails airline={item.options.extends_airline} />
                  )}
                </>
              ) : (
                <Typography variant="caption">
                  {dateFormat(item.options.start)}
                  <br />
                  {item.quantity} {item.quantity > 1 ? "personnes" : "personne"}
                </Typography>
              )}
            </Grid>
            <Grid container item xs={6} md={5} alignItems="flex-start">
              <Grid container item md={12} justify="flex-end">
                <Typography
                  variant="body2"
                  align="right"
                  style={{ width: "100%", fontSize: 14 }}
                >
                  {detailedPrice} <br />
                  {optionVol}
                </Typography>
                <Typography
                  variant="h5"
                  align="right"
                  style={{ width: "100%" }}
                >
                  = {priceTotal}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <hr className={classes.hrColor} />
        </React.Fragment>
      )
    })
  }

  return (
    <Paper elevation={1} className={`${classes.containerCart} cart`}>
      <Grid
        container
        alignItems="center"
        className={`${classes.containerGrid}`}
      >
        <Grid item xs={6} md={6}>
          <Typography variant="h4">Panier</Typography>
        </Grid>
        <Grid container item xs={6} md={6} justify="flex-end">
          <Button
            style={{ marginTop: 0 }}
            color="secondary"
            startIcon={<CreateIcon />}
            onClick={handleClick}
          >
            Editer
          </Button>
        </Grid>
      </Grid>

      {renderItems(itemProducts)}

      <Grid container className={classes.containerGrid}>
        <RenderExtraOptions options={extraOptions} quantity={quantity} />

        {coupon && <RenderCoupon coupon={coupon} />}

        <Grid container item>
          <Grid item md={8} xs={8}>
            <Typography variant="h4" color="secondary">
              Total
            </Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography variant="h4" color="secondary" align="right">
              {getTotals()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
