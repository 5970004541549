import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { language as t } from "./../../language"
import {
  makeStyles,
  Grid,
  Card,
  CardMedia,
  Typography,
  Box,
} from "@material-ui/core"

const useStyle = makeStyles({
  card: {
    borderRadius: 10,
    "& .MuiTypography-body1": {
      "& strong": {
        fontFamily: "modulus-pro-bold",
      },
    },
  },
  media: {
    width: "100%",
    height: 293,
    color: "white",
    "& .MuiGrid-container": {
      height: "100%",
    },
    "& .MuiGrid-item": {
      background:
        "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8813900560224089) 50%, rgba(0,0,0,0) 100%)",
      width: "100%",
      textAlign: "center",
    },
  },
  boxTitle: {
    color: "#ff4545",
    textAlign: "center",
    width: "100%",
    paddingTop: "15px",
  },
  boxDescription: {
    textAlign: "center",
    padding: "7px",
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
  },
})

const ProductItem = ({ id, title, img, price, attributes, description }) => {
  const classes = useStyle()
  return (
    <Box>
      <Link to={`/product/${id}`} style={{ textDecoration: "none" }}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image={img}
            title={title}
            alt={title}
          >
            <Grid container alignContent="flex-end">
              <Grid item>
                <Typography variant="body1" component="span">
                  <strong>{price}</strong> / {t.person}
                </Typography>
              </Grid>
            </Grid>
          </CardMedia>
        </Card>
      </Link>
      <Grid container>
        <Grid item className={classes.boxTitle}>
          <Link to={`/product/${id}`} style={{ textDecoration: "none" }}>
            <Typography variant="h5" component="span" color="secondary">
              {title}
            </Typography>
          </Link>
        </Grid>
        <Grid item className={classes.boxDescription}>
          <Typography
            variant="body1"
            component="span"
            dangerouslySetInnerHTML={{ __html: description }}
          ></Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

ProductItem.propTypes = {}

export default ProductItem
