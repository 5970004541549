import React, { useEffect, useContext } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom"
import "./App.css"
import { makeStyles } from "@material-ui/core"
import Header from "./Components/Header"
import Checkout from "./Components/Checkout"
//import { HomeScreen } from "./Components/HomeScreen"
import { HomeScreen } from "./Page/HomeScreen"
import CartContext from "./Context/CartContext"
import Cart from "./Classes/Cart"
import { useState } from "react"
import { StoreContext, StoreProvider } from "./Context/StoreContext"
import { InformationPage } from "./Components/InformationPage/InformationPage"
import { language as t } from "./language"
import { ContactPage } from "./Components/ContactPage/ContactPage"
import { ContactPageV2 } from "./Components/ContactPage/ContactPageV2"
import { ThankYouPage } from "./Components/ThankYouPage"
import { Productpage } from "./Components/ProductPage"
import CheckDataInSession from "./Components/CheckDataInSession"
import { getSession } from "./Helpers/session"
import localStorageCache from "./Helpers/localStorageCache"
import { ContactReclamationPage } from "./Components/ContactPage/ContactReclamationPage"
import { ProductsContextProvider } from "./Context/ProductsContext"
import { useSnackbar } from "notistack"

const useStyle = makeStyles((theme) => ({
  boxContainer: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 999,
    textAlign: "center",
    "& .MuiGrid-root": {
      position: "relative",
      top: "50%",
    },
  },
  containerCheckout: {
    backgroundColor: "#eeeeee",
  },
}))

let cart = Cart

const App = (props) => {
  const [change, setChange] = useState(0)
  const [email, setEmail] = useState("")
  const [visibility, setVisibility] = useState(true)
  const [session, setSession] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const classes = useStyle()
  const store = useContext(StoreContext)

  const getSessionData = async (email) => {
    const { data, success, prev_product } = await getSession(email)
    if (success) {
      if (data) {
        const json = data
        const { coupon, extraOptions, items } = json.cart
        localStorageCache.set("formValues", json.formValues)
        cart.cartCacheConfiguration(coupon, extraOptions, items)
        if (items.length > 0) {
          setSession(true)
        } else {
          enqueueSnackbar("The session expired, select the product again", {
            variant: "warning",
          })
        }
      }
      setEmail(email)
    }
  }

  useEffect(() => {
    if (history.location.search && email === "") {
      getSessionData(new URLSearchParams(history.location.search).get("email"))
    }
  }, [])

  useEffect(() => {
    const run = async () => {
      if (email != "") {
        if (!history.location.search) {
          getSessionData(email)
        }
      }
      if (history.location.search) {
        setVisibility(false)
      }
    }
    run()
  }, [email])

  return (
    <ProductsContextProvider>
      <CartContext.Provider
        value={{ cart, change, setChange, email, setEmail }}
      >
        <Switch>
          <Route exact path="/">
            <HomeScreen store={store} redirect={session} />
            {visibility && <CheckDataInSession setVisibility={setVisibility} />}
          </Route>
          <Route path="/checkout/">
            <div className={classes.containerCheckout}>
              <Header
                title={`${t.title_header} ${store.store_name}`}
                store_image={store.store_image}
                logo={store.logo}
                isCheckOutPage
              />
              <Checkout />
            </div>
          </Route>
          <Route exact path="/product/:id" component={Productpage} />
          <Route path="/terms">
            <InformationPage store={store} slug="metions-legales" />
          </Route>
          <Route path="/contact">
            <ContactPageV2
              store={store}
              config_email={store.config_contact_email}
              mail_cc={store.config_contact_email_cc || null}
              title="Vous avez encore une question, un doute sur l&#39;offre proposée ?"
              subtitle="Vous pouvez nous adresser vos demandes ici !"
            />
          </Route>
          <Route path="/contact-sav">
            <ContactPageV2
              store={store}
              config_email={store.config_sav_email}
              mail_cc={store.config_sav_email_cc || null}
              title="Vous avez des questions suite à votre achat sur notre mini-site ?"
              subtitle="Faites-nous parvenir vos demandes et commentaires ici ! "
              sav
            />
          </Route>
          <Route path="/contact-reclamation">
            <ContactReclamationPage
              store={store}
              config_email={store.config_reclamations_email}
              mail_cc={store.config_reclamations_email_cc || null}
              title="Vous avez des questions suite à votre achat sur notre mini-site ?"
              subtitle="Faites-nous parvenir vos demandes et commentaires ici ! "
            />
          </Route>
          <Route path="/thank-you">
            <ThankYouPage store={store} slug="thank-you" />
          </Route>
          <Redirect to="/" />
        </Switch>
      </CartContext.Provider>
    </ProductsContextProvider>
  )
}

export default App
