import React from "react"
import { createContext } from "react"

import { useFetch } from "../hooks/useFetch"
import ajax from "../Helpers/ajax"
import { Loading } from "../Components/Loading/Loading"

import Cart from "../Classes/Cart"
import localStorageCache from "../Helpers/localStorageCache"

let cart = Cart
const cartCache = localStorageCache.get("cart")

if (cartCache) {
  const { coupon, extraOptions, items } = cartCache
  cart.cartCacheConfiguration(coupon, extraOptions, items)
}

const CartContext = createContext({ cart, products: [] })

export function CartContextProvider({ children }) {
  const { data, loading } = useFetch(ajax.getProducts())
  const products = data?.data

  return (
    <>
      <CartContext.Provider value={{ cart }}>{children}</CartContext.Provider>
    </>
  )
}

export default CartContext
