import React, { useContext, useEffect } from "react"
import CartContext from "../../Context/CartContext"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import ProductsContext from "../../Context/ProductsContext"
import localStorageCache from "../../Helpers/localStorageCache"
import { formOptionsVol, FormPassengerJSON } from "../../Helpers/validation"
import { useForm } from "../../hooks/useForm"

export const CheckoutFormProvider = ({ forms, children }) => {
  const { cart } = useContext(CartContext)
  const products = useContext(ProductsContext)
  const { passenger, contact_info, option_vol } = forms

  //let { passenger, contact_info, option_vol } = {}
  const quantity = cart.getTotalPersons()

  const product = cart.getFirstItem()

  const useform = useForm({
    passenger,
    contact_info,
    option_vol,
  })

  return (
    <>
      {passenger && (
        <CheckoutFormContext.Provider value={{ ...useform }}>
          {children}
        </CheckoutFormContext.Provider>
      )}
    </>
  )
}
