import React, { useContext, useState } from 'react'
import { Grid, Typography, TextField, Button, } from '@material-ui/core'
import { useForm } from '../../hooks/useForm'
import ajax from '../../Helpers/ajax'
import CartContext from '../../Context/CartContext'

export const CodePromo = () => {
    const { cart, change, setChange } = useContext(CartContext)
    const currentCoupon = cart.getCoupon()
    const [error, setError] = useState(null)
    const {formValues, handleInputChange, reset} = useForm({ coupon:'' })
    const { coupon } = formValues

    const fetchData = async (url, initial) => {
        const response = await fetch(url, initial)
        const data = await response.json()
    
        return data
    }
    const handleClick = async () => {
        setError(null)
        if(!coupon){
            setError({
                coupon:{
                    helperText:"Renseigner un coupon valide",
                    error:true
                }
            })
            return null
        }

        const { url, initial } = ajax.validateCoupon(JSON.stringify({coupon}))
        const response = await fetchData(url, initial)

        if(response.success){
            const { code, name, discount , type } = response.data
            cart.addCoupon({ code, name, discount , type })
            setChange(change+1)
        }else{
            alert(response.error)
        }
        
    }

    const removeCoupon = () =>{
        reset()
        cart.removeCoupon()
        setChange(change+1)
    }
    return (
        <Grid container item md={10} style={{padding:'0 20px'}}>
            <Grid item md={12}>
                <Typography variant="h4" style={{paddingTop:20}}>
                Code promo
                </Typography>
            </Grid>
            <Grid item md={12}>
                <TextField 
                    label="Entrez votre code"
                    value={coupon}
                    name="coupon"
                    onChange={handleInputChange}
                    {...error?.coupon}
                    />
                    {
                        (!currentCoupon) ? 
                            <Button  variant="contained" onClick={handleClick} color="secondary" disableElevation >Valider</Button>
                            :
                            <Button  variant="contained" onClick={removeCoupon} color="secondary" disableElevation >Retirer le code promo</Button>
                    }
                    
            </Grid>
        </Grid>
    )
}
