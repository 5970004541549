import React, { useContext } from "react"
import {
  Container,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { ContentNotFound } from "../ContentNotFound/ContentNotFound"
import CheckIcon from "@material-ui/icons/Check"
import HeaderProduct from "../Header/HeaderProduct"
import { StoreContext } from "../../Context/StoreContext"
import Footer from "../Footer"
import _ from "lodash"
import { PriceBox } from "./PriceBox"
import GridImages from "../GridImages"
import ProductsContext from "../../Context/ProductsContext"

const useStyle = makeStyles({
  container: {
    margin: "30px 0",
  },
  paperContainer: {
    marginTop: 30,
    marginBottom: 30,
  },
  gridContainer: {
    padding: 10,
  },
  cardMedia: {
    borderRadius: 10,
    height: 259,
    width: "100%", //194
  },
  content: {
    "& img": {
      width: "100% !important",
    },
  },
  attributes: {
    padding: 0,
    listStyle: "none",
    "& .MuiSvgIcon-root": {
      top: "7px",
      position: "relative",
    },
  },
  price: {},
  sale: {
    textDecoration: "line-through",
    fontSize: 15,
  },
})

export const Productpage = ({ match, location }) => {
  const classes = useStyle()
  const {
    store_name,
    store_image,
    logo,
    config_info_open_start: open_start,
    config_info_open_end: open_end,
  } = useContext(StoreContext)

  const products = useContext(ProductsContext)
  const id = match.params.id
  const [productFiltered] = products.filter(
    (product) => product.id === parseInt(id)
  )
  const {
    name,
    description,
    attribute_groups,
    original_images,
  } = productFiltered ? productFiltered : {}

  return (
    <>
      <HeaderProduct title={name} store_image={store_image} logo={logo} />
      <Container maxWidth="lg">
        {productFiltered ? (
          <Grid container className={classes.container}>
            <Grid item md={9}>
              <GridImages gallery={original_images} />
              {/*<Typography variant="h1">{name}</Typography>*/}
              <div>
                <Typography
                  variant="body1"
                  className={classes.content}
                  dangerouslySetInnerHTML={{ __html: _.unescape(description) }}
                ></Typography>
              </div>
              <div>
                <ul className={classes.attributes}>
                  {attribute_groups.map((group) =>
                    group.attribute.map((attribute) => (
                      <li key={attribute.attribute_id + attribute.text}>
                        <Typography variant="body2" component="p">
                          <CheckIcon color="secondary"></CheckIcon>
                          {` ${attribute.text}`}
                        </Typography>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </Grid>
            <Hidden smDown>
              <Grid item md={3}>
                <PriceBox
                  product={productFiltered}
                  open_start={open_start}
                  open_end={open_end}
                />
              </Grid>
            </Hidden>
          </Grid>
        ) : (
          <ContentNotFound />
        )}
      </Container>
      <Footer logo={logo} />
    </>
  )
}
