import { getOption, searchProduct } from "./products"
import config from "./../Config"
import {
  dateFormatOfTodayWithEighteenLessYears,
  makeJSDateObject,
} from "./dateFormat"

export const validateBillingForm = ({
  name,
  email,
  address,
  city,
  country,
}) => {
  let errors = []
  if (name.length <= 0 || name === "") {
    errors.name = {
      helperText: "Renseigner un nom de famille ",
      error: true,
    }
  }

  if (!isValidEmail(email)) {
    errors.email = {
      helperText: "Renseigner une adresse email",
      error: true,
    }
  }

  if (address.length <= 0 || address === "") {
    errors.address = {
      helperText: "Renseigner une adresse postale valide",
      error: true,
    }
  }

  if (city.length <= 0 || city === "") {
    errors.city = {
      helperText: "Renseigner un ville ",
      error: true,
    }
  }

  if (country.length <= 0 || country === "") {
    errors.country = {
      helperText: "Renseigner un pays ",
      error: true,
    }
  }

  return errors
}

export const validatePassenger = (passengers) => {
  let errors = []
  for (let index in passengers) {
    let error = {}
    let passenger = passengers[index]

    if (passenger.sex.length <= 0 || passenger.sex === "") {
      error.sex = {
        helperText: "Sélectionner une civilité",
        error: true,
      }
    }
    if (passenger.firstname.length <= 3 || passenger.firstname === "") {
      error.firstname = {
        helperText: "Renseigner un prénom",
        error: true,
      }
    }

    if (passenger.lastname.length <= 3 || passenger.lastname === "") {
      error.lastname = {
        helperText: "Renseigner un nom de famille",
        error: true,
      }
    }

    if (passenger.passport_complete_later !== undefined) {
      if (!passenger.passport_complete_later) {
        if (
          passenger.passport_number.length <= 3 ||
          passenger.passport_number === ""
        ) {
          error.passport_number = {
            helperText: " Renseigner un n. de passeport",
            error: true,
          }
        }
        if (
          passenger.passport_place_of_emission.length <= 3 ||
          passenger.passport_place_of_emission === ""
        ) {
          error.passport_place_of_emission = {
            helperText: "Renseigner un lieu d’émission du passeport",
            error: true,
          }
        }
      }
    }

    //    if( passenger.loyalty_program !== undefined ){
    //         if( !passenger.loyalty_program.name  ){
    //                 error.loyalty_program = {
    //                     helperText:"Incorrect loyalty_program.",
    //                     error:true
    //                 }
    //         }
    //    }
    /*
    if (passenger.luggage_depart !== undefined) {
      if (Object.entries(passenger.luggage_depart).length === 0) {
        error.luggage_depart = {
          helperText: "Incorrect luggage_depart.",
          error: true,
        }
      }

      if (Object.entries(passenger.luggage_return).length === 0) {
        error.luggage_return = {
          helperText: "Incorrect luggage_return.",
          error: true,
        }
      }
    }
    */
    //    if(passenger.special_needs !== undefined ){
    //         if(  Object.entries(passenger.special_needs).length === 0 ){
    //             error.special_needs = {
    //                 helperText:"Incorrect special_needs.",
    //                 error:true
    //             }
    //         }
    //    }

    errors[index] = error
  }
  return errors
}

export const validateContactInfo = (contacts_info) => {
  let errors = []

  for (let index in contacts_info) {
    let error = {}
    let contact_info = contacts_info[index]
    if (contact_info.contact_complete_later !== undefined) {
      if (!contact_info.contact_complete_later) {
        if (contact_info.mobile.length <= 3 || contact_info.mobile === "") {
          error.mobile = {
            helperText: "Renseigner un n. de téléphone portable.",
            error: true,
          }
        }

        if (contact_info.email.length <= 3 || contact_info.email === "") {
          error.email = {
            helperText: "Renseigner une adresse email",
            error: true,
          }
        }
      }
    }

    errors[index] = error
  }

  return errors
}

export const validateOptionsVol = (option_vol) => {
  let errors = {}
  const { insurances, additional_conditionals } = option_vol

  if (insurances !== undefined) {
    if (insurances.length === 0) {
      errors.insurances = {
        helperText: "Incorrect insurances.",
        error: true,
      }
    }
  }

  if (!additional_conditionals) {
    errors.additional_conditionals = {
      helperText: "Merci d’accepter nos conditions ",
      error: true,
    }
  }

  return errors
}

export const FormPassengerJSON = (products, product) => {
  if (!product) {
    return null
  }

  //Required
  let passengerJSON = {
    sex: "",
    firstname: "",
    lastname: "",
    nationality: "fr",
    dob: dateFormatOfTodayWithEighteenLessYears(),
    comment: "",
  }

  let contactInfoJson = {}

  if (product.category === "package") {
    passengerJSON.passport_number = ""
    passengerJSON.passport_country = "fr"
    passengerJSON.passport_emission_date = new Date()
    passengerJSON.passport_expiration_date = new Date()
    passengerJSON.passport_place_of_emission = ""
    passengerJSON.passport_complete_later = false

    const LOYALTY = config.store.product.options.loyalty.name
    const LUGGAGE = config.store.product.options.baggage.name
    const SPECIAL = config.store.product.options.special.name

    const item = searchProduct(products, product?.product_id)
    const loyaltyOpt = getOption(item, LOYALTY)
    if (typeof loyaltyOpt?.name !== "undefined") {
      passengerJSON.loyalty_program = {}
    }

    if (product.options.extends_airline !== undefined) {
      const LuggageOpt = getOption(item, LUGGAGE)
      if (typeof LuggageOpt?.name !== "undefined") {
        passengerJSON.luggage_depart = []
        passengerJSON.luggage_return = []
      }
    }

    const specialOpt = getOption(item, SPECIAL)
    if (typeof specialOpt?.name !== "undefined") {
      passengerJSON.special_needs = {}
    }
    contactInfoJson.mobile = ""
    contactInfoJson.email = ""
    contactInfoJson.contact_complete_later = true
  }
  return { passengerJSON, contactInfoJson }
}

export const formContactInfoJson = () => {
  let contactInfoJson = {
    mobile: "",
    email: "",
    contact_complete_later: false,
  }

  return contactInfoJson
}

export const formOptionsVol = (products, product) => {
  if (!product) {
    return null
  }

  //Required
  let optionsVol = {
    additional_conditionals: false,
  }

  const item = searchProduct(products, product?.product_id)

  const INSURANCE = config.store.product.options.transfer.name

  const insurances = getOption(item, INSURANCE)
  if (typeof insurances?.name !== "undefined") {
    optionsVol.insurances = []
  }

  if (product.options.extends_airline !== undefined) {
    optionsVol.transfers = {
      depart: null,
      returnT: null,
    }
  }
  return optionsVol
}

export const generateFormForCart = (category) => {
  let form = {}
  switch (category) {
    case "event":
      form.name = category
      form.fields = {
        quantity: 1,
        start: makeJSDateObject(new Date()),
      }
      break
    case "package":
      form.name = category
      form.fields = {
        airline: "",
        vol: 0,
        quantity: 1,
        start: makeJSDateObject(new Date()),
        end: makeJSDateObject(new Date()),
      }
      break
    default:
      console.warn(
        "Warning ! La catégorie pour ce produit n'est pas configurée"
      )
      break
  }

  return form
}

export const validateContactForm = ({ name, lastname, email, message }) => {
  let errors = []
  if (name.length <= 0 || name === "") {
    errors.name = {
      helperText: "Renseigner un prénom",
      error: true,
    }
  }

  if (lastname.length <= 0 || lastname === "") {
    errors.lastname = {
      helperText: "Renseigner un nom de famille",
      error: true,
    }
  }

  if (!isValidEmail(email)) {
    errors.email = {
      helperText: "Renseigner une adresse email",
      error: true,
    }
  }

  if (message.length <= 0 || message === "") {
    errors.message = {
      helperText: "Renseigner une message valide",
      error: true,
    }
  }

  return errors
}

export const validateContactReclamationForm = ({
  name,
  lastname,
  email,
  reservation,
  reclamation,
  reason,
}) => {
  let errors = []
  if (name.length <= 0 || name === "") {
    errors.name = {
      helperText: "Renseigner un prénom",
      error: true,
    }
  }

  if (lastname.length <= 0 || lastname === "") {
    errors.lastname = {
      helperText: "Renseigner un nom de famille ",
      error: true,
    }
  }

  if (!isValidEmail(email)) {
    errors.email = {
      helperText: "Renseigner une adresse email",
      error: true,
    }
  }

  if (reservation.length <= 0 || reservation === "") {
    errors.reservation = {
      helperText: "Renseigner une message valide",
      error: true,
    }
  }

  if (reclamation.length <= 0 || reclamation === "") {
    errors.reclamation = {
      helperText: "Renseigner une message valide",
      error: true,
    }
  }

  if (reason.length <= 0 || reason === "") {
    errors.reason = {
      helperText: "Renseigner une message valide",
      error: true,
    }
  }

  return errors
}

const isValidEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(email)) {
    return true
  }

  return false
}
