import React, { useState } from "react"
import { Grid, makeStyles, Typography, Box, Button } from "@material-ui/core"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import { language as t } from "./../../../language"
import CartDialog from "./../../CartDialog"
import { priceFormat } from "./../../../Helpers/store"
import { dateFormat } from "./../../../Helpers/dateFormat"
import { getCategory } from "../../../Helpers/products"
import { generateFormForCart } from "../../../Helpers/validation"
import { onscroll } from "../../../Helpers/utils"

const useStyle = makeStyles({
  priceBox: {
    position: "relative",
    top: "-152px",
    "& .MuiTypography-h5": {
      color: "#ff4545",
      fontSize: "21px",
      textAlign: "center",
    },
    "& .MuiTypography-h4": {
      color: "#ff4545",
      fontSize: "36px",
      textAlign: "center",
    },
    "& .MuiTypography-body2": {
      textAlign: "center",
    },
  },
  container: {
    background: "#eeeeee",
    paddingTop: 30,
    paddingBottom: 30,
  },
  circle: {
    paddingTop: 10,
    textAlign: "center",
    borderRadius: "50%",
    height: 248,
    width: 248,
    background: "red",
    position: "absolute",
    "& img": {
      width: "25%",
      marginTop: 10,
    },
  },
  semiCircleTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    width: 248,
    height: 90,
    position: "relative",
    textAlign: "center",
    paddingTop: 35,
    borderTopLeftRadius: 127,
    borderTopRightRadius: 127,
    background: "#ffffff",
    //boxShadow: '0px -4px 5px 0px rgba(0, 0, 0, 0.2)',
    borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
    borderRight: "1px solid rgba(0, 0, 0, 0.05)",
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    "& .MuiTypography-h3": {
      position: "relative",
      top: 16,
    },
  },
  circleBottom: {
    bottom: 0,
    background: "#ffffff",
    textAlign: "center",
    width: 248,
    height: 124,
    position: "relative",
    borderBottomLeftRadius: 127,
    borderBottomRightRadius: 127,
    borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
    borderRight: "1px solid rgba(0, 0, 0, 0.05)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
  },
  body: {
    background: "#ffffff",
    maxWidth: 248,
    borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
    borderRight: "1px solid rgba(0, 0, 0, 0.05)",
    //boxShadow: '0px 1px 8px -2px rgba(0, 0, 0, 0.2)',
    "& .list": {
      //maxHeight: 140,
      zIndex: 99999,
      "& .MuiTypography-root": {
        fontSize: 14,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem",
      },
    },
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 37,
    },
    "& .MuiTypography-h2": {
      marginBottom: 0,
    },
    "& p": {
      width: "100%",
      textAlign: "center",
    },
  },
  fixedTop: {
    position: "fixed",
    top: 5,
    width: 394.66,
  },
})

export const PriceBox = ({ product, open_start, open_end }) => {
  const classes = useStyle()
  const { price, special, special_start_date, special_end_date } = product
  const bestPrice = special !== 0 ? priceFormat(special) : priceFormat(price)

  const date_start =
    special_start_date !== ""
      ? dateFormat(special_start_date, "MM/DD/YYYY")
      : open_start
  const date_end =
    special_end_date !== ""
      ? dateFormat(special_end_date, "MM/DD/YYYY")
      : open_end

  const [open, setOpen] = useState(false)

  const [category] = product ? getCategory(product) : []

  const cartForm = generateFormForCart(category?.name.toLowerCase())
  const { name, fields } = cartForm

  window.onscroll = () => onscroll(classes, ".price-box", 440)

  const handleOpen = () => {
    setOpen((o) => true)
  }
  const handleClose = () => {
    setOpen((o) => false)
  }
  return (
    <Grid
      container
      item
      md={9}
      alignContent="center"
      className={classes.priceBox}
    >
      <div className="price-box">
        <Box className={`${classes.semiCircleTop}`}>
          <div>
            {}
            <Typography variant="h5" component="div" color="secondary">
              Du {date_start}
            </Typography>
            <Typography variant="h5" color="secondary">
              au {date_end}
            </Typography>
          </div>
        </Box>
        <Box className={classes.body}>
          <Grid container justify="center" item>
            <Typography variant="h4" component="p" color="secondary">
              {bestPrice}
            </Typography>
            <Typography variant="body2" component="p">
              {t.per_person}
            </Typography>
          </Grid>
        </Box>
        <Box className={`${classes.circleBottom}`}>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="secondary"
            disableElevation
            endIcon={<ArrowRightIcon />}
          >
            Choisir une date
          </Button>
        </Box>
      </div>

      <CartDialog
        open={open}
        handleClose={handleClose}
        product={product}
        category={name}
        fields={fields}
      />
    </Grid>
  )
}
