import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { useFetch } from "../../../hooks/useFetch"
import ajax from "../../../Helpers/ajax"
import _ from "lodash"

const GetInformation = ({ name }) => {
  const infoPage = useFetch(ajax.getInformationPageByMetaTitle(name))

  const { loading, data: info } = infoPage
  const { data, error, success } = info || {}

  return (
    !loading &&
    data && (
      <Grid container item md={12}>
        <Grid container item md={12}>
          <Grid container item md={12} alignItems="center">
            <Typography variant="h6">{data.title}</Typography>
          </Grid>
          <Grid container item md={12} alignItems="center">
            <Typography
              variant="caption"
              dangerouslySetInnerHTML={{ __html: _.unescape(data.description) }}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  )
}

export default GetInformation
