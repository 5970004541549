import React, { useEffect } from "react"
import { Grid, Button, makeStyles, Typography } from "@material-ui/core"
import { useState } from "react"
import { MemoryRouter } from "react-router-dom"
import { ErrorMessage } from "../ErrorMessage/ErrorMessage"

const useStyle = makeStyles({
  root: {
    paddingRight: 10,
    "& Button": {
      borderRadius: 7,
      padding: 5,
      minWidth: 59,
    },
    "& Button.active": {
      border: `1px solid red`,
    },
  },
})

export const Civilite = ({
  label,
  value,
  pos,
  handleInputChangePassenger,
  error,
}) => {
  const classes = useStyle()
  const [civilite, setCivilite] = useState(value)

  useEffect(() => {
    handleInputChangePassenger({ name: label, value: civilite }, pos)
  }, [civilite])

  const mr = `mr${pos}`
  const mme = `mme${pos}`

  const selectedMme = (e) => {
    document.querySelector(`#${mr}`).classList.remove("active")
    document.querySelector(`#${mme}`).classList.add("active")
    setCivilite("Mme")
  }
  const selectedMr = () => {
    document.querySelector(`#${mme}`).classList.remove("active")
    document.querySelector(`#${mr}`).classList.add("active")
    setCivilite("Mr")
  }
  return (
    <Grid container className={classes.root}>
      <Grid item md={12} xs={12}>
        <Typography variant="caption">Civilité</Typography>
      </Grid>
      <Grid item md={6}>
        <Button
          id={mr}
          className={"Mr" === civilite && "active"}
          onClick={selectedMr}
        >
          Mr
        </Button>
      </Grid>
      <Grid item md={6}>
        <Button
          id={mme}
          className={"Mme" === civilite && "active"}
          onClick={selectedMme}
        >
          Mme
        </Button>
      </Grid>
      <input type="hidden" name="civilite" value={civilite} />
      <ErrorMessage error={error} />
    </Grid>
  )
}
