import React, { useEffect, useContext, useState } from "react"
import { SelectionSeat } from "./SelectionSeat"
import { AddTransfer } from "./AddTransfer"
import Insurance from "./Insurances/Insurances"
import {
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core"

import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import { useHistory } from "react-router-dom"

import ScrollToTopOnMount from "../../hooks/useScrollToTopOnMount"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import { validateOptionsVol } from "../../Helpers/validation"
import { StoreContext } from "../../Context/StoreContext"
import { ErrorMessage } from "../ErrorMessage/ErrorMessage"
import { scrollToBody } from "../../Helpers/utils"
import CartContext from "../../Context/CartContext"
import { saveSession } from "../../Helpers/session"

export const OptionsVol = ({ setStep, additionalProducts }) => {
  const { cart, email } = useContext(CartContext)
  const {
    formValues,
    handleCheckboxOptions,
    handleCheckboxConditional,
  } = useContext(CheckoutFormContext)
  const store = useContext(StoreContext)

  const { option_vol } = formValues

  const { transfers, insurances, additional_conditionals } = option_vol

  const [errors, setErrors] = useState({})

  useEffect(() => {
    setStep(2)
  }, [])

  useEffect(() => {
    scrollToBody()
  }, [errors])

  let history = useHistory()

  const handleBack = () => {
    history.push("/checkout/step-1")
  }

  const handleNext = () => {
    const errors = validateOptionsVol(option_vol)
    if (Object.entries(errors).length !== 0) {
      setErrors(errors)
      return
    }

    saveSession(email, { formValues, cart })
    if (additionalProducts) {
      history.push("/checkout/step-3")
      return
    }
    history.push("/checkout/step-end")
  }

  return (
    <>
      <ScrollToTopOnMount />
      <Grid container item md={12}>
        {transfers && (
          <AddTransfer
            value={transfers}
            handleCheckboxOptions={handleCheckboxOptions}
          />
        )}

        {insurances && (
          <Insurance
            value={insurances}
            handleCheckboxOptions={handleCheckboxOptions}
            error={errors?.insurances ? errors?.insurances : {}}
          />
        )}

        <Paper elevation={3} style={{ padding: "20px 0", width: "100%" }}>
          <Grid container item md={12} style={{ padding: "0 20px" }}>
            <FormControlLabel
              value="20"
              control={
                <Checkbox
                  checked={additional_conditionals ? true : false}
                  onChange={() =>
                    handleCheckboxConditional(
                      "additional_conditionals",
                      !additional_conditionals
                    )
                  }
                />
              }
              label={store.config_additional_conditions}
              labelPlacement="end"
            />
            <ErrorMessage error={errors?.additional_conditionals} />
          </Grid>
        </Paper>

        <Grid container item md={12} style={{ margin: "20px 0" }}>
          <Grid item md={6} xs={6}>
            <Button
              variant="contained"
              onClick={() => handleBack("/checkout/step-1")}
              disableElevation
            >
              RETOUR INFOS {transfers ? "PASSAGER" : "CLIENT"}
            </Button>
          </Grid>
          <Grid container item md={6} xs={6} justify="flex-end">
            <Button
              variant="contained"
              onClick={() => handleNext("/checkout/step-3")}
              color="secondary"
              disableElevation
              endIcon={<PlayArrowIcon />}
            >
              Etape suivante
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
