import React from 'react'
import PropTypes from 'prop-types'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useFetch } from '../../hooks/useFetch';
import ajax from '../../Helpers/ajax';
import config from '../../Config'

const useStyle = makeStyles({
    root:{
        padding:30,
    },
    gridContainer:{
        marginTop: 15,
        marginBottom:15
    }
})

function GridAttribute({ title} ) {
    const classes = useStyle()

    const { data, loading} = useFetch( ajax.getAttributeByName(config.store.attribute.name) )

    const attributes = data?.data

    const renderAttribute = () => (
        attributes.map( attribute => <Grid key={attribute.attribute_id} item xs={4}><Typography variant="body1" component="p"><ThumbUpIcon style={{top: '7px',position: 'relative',}} color="secondary"/> {attribute.name}</Typography></Grid> )
    )

    return (
        !loading ? 
            (
                <Grid container item={true} md={8} className={classes.gridContainer} spacing={4}>
                    <Grid container item xs={12} >
                        <Typography variant="h4">
                        {title}
                        </Typography>
                    </Grid>

                    {renderAttribute()}
                    
                </Grid>
            ) :
            (
                <h2>Chargement...</h2>
            )
        
    )
}

GridAttribute.propTypes = {
    title: PropTypes.string,
}

export default React.memo(GridAttribute)

