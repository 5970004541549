import React from 'react'
import { Paper, Grid, Typography, Icon, makeStyles, Button } from '@material-ui/core'

const useStyle = makeStyles({
    containerGrid:{
        padding:'0 20px',
        marginBottom:30,
    },
    containerPadding:{
        padding:'20px 0'
    },
    button:{
        borderRadius:6
    }
})

export const SelectionSeat = () => {
    const classes =  useStyle()
    return (
        <Paper elevation={3} className={classes.containerGrid}>
            <Grid container item md={10} className={classes.containerPadding}>
                <Grid item md={12}>
                    <Typography variant="h4">
                        Sélection du siége
                    </Typography>
                </Grid>
                <Grid item md={12} style={{padding:'20px 0'}}>
                    <Typography variant="h5">
                        Vol aller
                    </Typography>
                </Grid>
                <Grid container item md={12}>
                    <Grid container item md={12}>
                        <Grid container item md={6} alignItems="center">
                            <Icon style={{fontSize:52, color:'#737373'}}>person</Icon>
                            <Typography variant="caption">
                                Jean-Pierre Morand
                            </Typography>
                        </Grid>
                        <Grid container item md={6} alignItems="center">
                            <Grid item md={5}>
                                <Typography variant="h5" align="center">
                                Siège A1
                                </Typography>
                            </Grid>
                            <Grid item md={7}>
                                <Button className={classes.button} variant="outlined">CHANGER DE Siège</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item md={12}>
                        <Grid container item md={6} alignItems="center">
                            <Icon style={{fontSize:52, color:'#737373'}}>person</Icon>
                            <Typography variant="caption">
                                Juliette Morand
                            </Typography>
                        </Grid>
                        <Grid container item md={6}  alignItems="center">
                            <Grid item md={5}>
                                <Typography variant="h5" align="center">
                                Siège A1
                                </Typography>
                            </Grid>
                            <Grid item md={7}>
                                <Button className={classes.button} variant="outlined">CHANGER DE Siège</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Paper>
    )
}
