import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export const ContentNotFound = () => {
    return (
        <Grid container item xs={12} >
        <Grid item md={12}>
                <Typography variant="h1">
                    Content not Found
                </Typography>
        </Grid>
    </Grid>
    )
}
