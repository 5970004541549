import React, { useEffect, useState } from "react"
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Button,
  FormHelperText,
} from "@material-ui/core"
import ScrollToTopOnMount from "../../hooks/useScrollToTopOnMount"

import PlayArrowIcon from "@material-ui/icons/PlayArrow"

import visa from "./img/visa.png"
import master from "./img/master_card.png"
import paypal from "./img/paypal.png"
import { CodePromo } from "../CodePromo/CodePromo"
import { useContext } from "react"
import CartContext from "../../Context/CartContext"
import { StoreContext } from "../../Context/StoreContext"
import { BillingFormDialog } from "../BillingFormDialog/BillingFormDialog"
import { priceFormat } from "../../Helpers/store"

const useStyle = makeStyles({
  containerGrid: {
    padding: "20px 10px 20px 20px",
  },
  containerPadding: {
    padding: "20px 0",
  },
  paperMargin: {
    marginBottom: 30,
  },
  hrColor: {
    border: 0,
    height: 1,
    backgroundColor: "#eeeeee",
  },
})

export const Payment = ({ setStep, additionalProducts }) => {
  const classes = useStyle()

  const [open, setOpen] = useState(false)
  const [payment, setPayment] = useState("")
  const [terms, setTerms] = useState(false)

  const [error, setError] = useState({})

  const { cart } = useContext(CartContext)
  const store = useContext(StoreContext)

  const total = cart.getTotals()

  const item = cart.getFirstItem()
  const { product_id, name, quantity, options } = item

  useEffect(() => {
    if (additionalProducts) {
      setStep(4)
    } else {
      setStep(3)
    }
  }, [])

  const handleRadioChange = (event) => {
    setPayment(event.target.value)
    if (error.paymentMethod) {
      let newValue = error
      delete newValue.paymentMethod
      setError(newValue)
    }
  }

  const handleCheckboxChange = (event) => {
    setTerms(event.target.checked)
    if (error.terms) {
      let newValue = error
      delete newValue.terms
      setError(newValue)
    }
  }

  const handleClick = () => {
    let error = {}

    if (payment === "") {
      error.paymentMethod = {
        error: true,
        helpertext: "Choisir une méthode de paiement",
      }
    }

    if (!terms) {
      error.terms = {
        error: true,
        helpertext: "Merci d’accepter nos conditions de vente",
      }
    }

    setError(error)

    if (Object.entries(error).length === 0) {
      setOpen(true)
    }
  }

  return (
    <>
      <ScrollToTopOnMount />

      <Grid container item md={12}>
        <Paper
          elevation={3}
          className={classes.paperMargin}
          style={{ width: "100%" }}
        >
          <Grid item md={12} className={`${classes.containerGrid}`}>
            <Grid item md={12}>
              <Typography variant="h4">Récapitulatif de l’offre</Typography>
            </Grid>

            <Grid item md={12}>
              <Typography variant="body1">
                Vous êtes sur le point de réserver un {name} pour {quantity}{" "}
                {quantity > 1 ? "personnes" : "personne"} <br />
                {options.extends_airline &&
                  `Le prix inclut le vol depuis ${options.extends_airline.departure_airport} to ${options.extends_airline.arrival_airport}.`}
              </Typography>
            </Grid>

            <Grid item md={12}>
              <Typography variant="caption">
                Le montant de votre commande :
              </Typography>
              <Typography
                variant="caption"
                color="secondary"
                style={{ marginLeft: 5 }}
              >
                {priceFormat(total)}
              </Typography>
            </Grid>
          </Grid>

          <CodePromo />

          <hr className={classes.hrColor} />
          <Grid
            item
            md={12}
            className={classes.containerGrid}
            style={{ display: "none" }}
          >
            <Grid item md={12}>
              <Typography variant="h4">Conditions d’annulation</Typography>
            </Grid>

            <Grid item md={12}>
              <Typography variant="body1">
                Ut nec massa ac turpis pretium suscipit quis nec turpis.
                Pellentesque quis orci gravida, placerat nisi nec, pellentesque
                dui. Interdum et malesuada fames ac ante ipsum primis in
                faucibus. Phasellus ut gravida ex, vel tristique nisi. Nunc
                tincidunt vulputate sapien, at dignissim massa rutrum laoreet.
                Nulla facilisi. Nunc auctor ut purus et hendrerit. Pellentesque
                habitant morbi tristique senectus et netus et malesuada fames ac
                turpis egestas. Suspendisse laoreet at metus in feugiat. Donec
                lobortis nibh ut egestas cursus. Aenean risus arcu, congue eu
                congue quis, egestas quis velit. Orci varius natoque penatibus
                et magnis dis parturient montes, nascetur ridiculus mus.
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          elevation={3}
          className={classes.paperMargin}
          style={{ width: "100%" }}
        >
          <Grid
            container
            item
            md={12}
            style={{ padding: "10px 20px 10px 20px" }}
          >
            <Grid item md={8}>
              <Typography variant="h4" component="p">
                Montant à régler
              </Typography>
            </Grid>

            <Grid item md={4}>
              <Typography variant="h4" color="secondary" align="right">
                {priceFormat(total)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          elevation={3}
          className={classes.paperMargin}
          style={{ width: "100%" }}
        >
          <Grid
            container
            item
            md={12}
            style={{ padding: "10px 20px 10px 20px" }}
          >
            <Grid item md={12}>
              <Typography variant="h4" component="p">
                Mode de réglement
              </Typography>
            </Grid>

            <Grid item md={12} style={{ padding: "10px 0" }}>
              <RadioGroup
                aria-label="payment-method"
                error={error.paymentMethod && "true"}
                name="payment-method"
                value={payment}
                onChange={handleRadioChange}
              >
                <Grid container item md={12} alignItems="center">
                  <Grid item md={1}>
                    <img src={visa} alt="visa" />
                  </Grid>
                  <Grid item md={9}>
                    <FormControlLabel
                      value="visa"
                      control={<Radio />}
                      label="Visa"
                    />
                  </Grid>
                </Grid>

                <Grid container item md={12} alignItems="center">
                  <Grid item md={1}>
                    <img src={master} alt="visa" />
                  </Grid>
                  <Grid item md={9}>
                    <FormControlLabel
                      value="master"
                      control={<Radio />}
                      label="Master Card"
                    />
                  </Grid>
                </Grid>

                <Grid container item md={12} alignItems="center">
                  <Grid item md={1}>
                    <img src={paypal} alt="visa" />
                  </Grid>
                  <Grid item md={9}>
                    <FormControlLabel
                      value="Paypal"
                      control={<Radio />}
                      label="Paypal"
                    />
                  </Grid>
                </Grid>
                <FormHelperText error={true}>
                  {error.paymentMethod && error.paymentMethod.helpertext}
                </FormHelperText>
              </RadioGroup>
            </Grid>

            <Grid item md={12}>
              <FormControlLabel
                value="20"
                control={
                  <Checkbox checked={terms} onChange={handleCheckboxChange} />
                }
                label={store.config_terms_of_sale}
                labelPlacement="end"
              />
              <FormHelperText error={true}>
                {error.terms && error.terms.helpertext}
              </FormHelperText>
            </Grid>

            <Grid item md={12}>
              <Button
                variant="contained"
                onClick={handleClick}
                color="secondary"
                disableElevation
                endIcon={<PlayArrowIcon />}
              >
                PAYER
              </Button>
            </Grid>

            <BillingFormDialog
              open={open}
              setOpen={setOpen}
              payment={payment}
            />
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}
