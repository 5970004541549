import React, { useContext } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import CartContext from "../../Context/CartContext"

export default function CheckDataInSession({ setVisibility }) {
  const [open, setOpen] = React.useState(true)
  const { email, setEmail } = useContext(CartContext)
  const [input, setInput] = React.useState(email)

  const handleSaveEmail = () => {
    setEmail(input)
    setOpen(false)
    setVisibility(false)
  }

  const handleClose = () => {
    setOpen(false)
    setVisibility(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            Pour conserver ou récupérer votre session, merci de renseigner votre
            email!
          </DialogContentText>
          <TextField
            autoComplete="off"
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            name="email"
            value={input}
            onChange={(e) => {
              setInput(e.target.value)
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Non merci
          </Button>
          <Button onClick={handleSaveEmail} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
