import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import React, { useState } from "react"
import ajax from "../../Helpers/ajax"
import { validateContactReclamationForm } from "../../Helpers/validation"
import { useForm } from "../../hooks/useForm"
import { useSnackbar } from "notistack"
import Footer from "../Footer"
import Header from "../Header"

const useStyle = makeStyles({
  bodyContent: {
    padding: 30,
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
})

export const ContactReclamationPage = ({
  store,
  config_email,
  mail_cc,
  title,
  subtitle,
}) => {
  const classes = useStyle()
  const [errors, setErrors] = useState(null)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { formValues, handleInputChange, reset } = useForm({
    name: "",
    lastname: "",
    email: "",
    reservation: "",
    reclamation: "",
    reason: "",
  })
  const { store_name, store_image, logo, config_contact_email } = store
  const { name, lastname, email, reservation, reclamation, reason } = formValues

  const fetchData = async (url, initial) => {
    const response = await fetch(url, initial)
    const data = await response.json()

    return data
  }

  const handleClick = async () => {
    const errors = validateContactReclamationForm(formValues)
    if (Object.entries(errors).length !== 0) {
      setErrors(errors)
      return
    }
    //const config_email = config_contact_email
    const enquiry = `Nom:${name}
      Prénom:${lastname}
      Votre adresse email:${email}
      Référence achat ou numéro de facture:${reservation}
      Prestation liée à votre réclamation:${reclamation}
      Motif de votre réclamation:${reason}`

    const { url, initial } = ajax.sendContact(
      JSON.stringify({ name, config_email, email, enquiry, cc: mail_cc })
    )

    setLoading(true)
    const response = await fetchData(url, initial)
    if (response.success) {
      enqueueSnackbar("Votre demande a bien été envoyée", {
        variant: "success",
      })
      reset()
      setLoading(false)
    } else {
      enqueueSnackbar(response.error, { variant: "error" })
      setLoading(false)
    }
  }
  return (
    <>
      <Header title={store_name} store_image={store_image} logo={logo} />
      <Container className={classes.bodyContent} maxWidth="lg">
        <Grid container spacing={2}>
          <Typography variant="h2" style={{ marginBottom: 30 }}>
            {title}
          </Typography>
          <Typography variant="h3" style={{ marginBottom: 30 }}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid container direction="column" spacing={2}>
          <Grid item md={6}>
            <TextField
              autoComplete="off"
              label="Nom"
              name="lastname"
              value={lastname}
              onChange={handleInputChange}
              fullWidth
              {...errors?.lastname}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              autoComplete="off"
              label="Prénom"
              name="name"
              value={name}
              onChange={handleInputChange}
              fullWidth
              {...errors?.name}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label="Votre adresse email"
              name="email"
              value={email}
              onChange={handleInputChange}
              fullWidth
              {...errors?.email}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label="Référence achat ou numéro de facture"
              name="reservation"
              value={reservation}
              onChange={handleInputChange}
              fullWidth
              {...errors?.reservation}
            />
          </Grid>
          <Grid item md={6} style={{ marginTop: 25 }}>
            <TextField
              id="reclamation"
              label="Prestation liée à votre réclamation"
              multiline
              fullWidth
              rows={4}
              name="reclamation"
              value={reclamation}
              onChange={handleInputChange}
              variant="outlined"
              {...errors?.reclamation}
            />
          </Grid>
          <Grid item md={6} style={{ marginTop: 25 }}>
            <TextField
              id="reason"
              label="Motif de votre réclamation"
              multiline
              fullWidth
              rows={4}
              name="reason"
              value={reason}
              onChange={handleInputChange}
              variant="outlined"
              {...errors?.reason}
            />
          </Grid>
          <Grid item md={6}>
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              onClick={handleClick}
            >
              Envoyer
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer logo={logo} />
    </>
  )
}
