import React, { useContext } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Grid,
  makeStyles,
  CardMedia,
  Typography,
  Box,
  Button,
  Hidden,
} from "@material-ui/core"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import { language as t } from "../../language"
import GridProducts from "../GridProducts"

import GridProductsCard from "../GridProductsCard"
import GridImages from "../GridImages"
import GridAttribute from "../GridAttribute"
import InfoAddress from "../InfoAddress"

import { bestPriceProduct } from "../../Helpers/products"
import { dateFormat } from "../../Helpers/dateFormat"
import { priceFormat } from "../../Helpers/store"
import { onscroll } from "../../Helpers/utils"

import ProductsContext from "../../Context/ProductsContext"

const useStyle = makeStyles({
  root: {
    padding: 30,
  },
  pTop: {
    paddingTop: 30,
  },
  pBottom: {
    paddingBottom: 30,
  },
  gridContainer: {
    marginTop: 15,
    marginBottom: 15,
  },
  media: {
    height: 74,
    width: 120,
    borderRadius: 10,
    boxShadow: "2px 2px 1px 0px rgba(0,0,0,0.1)",
  },
  fixedTop: {
    position: "fixed",
    top: 5,
    width: 394.66,
  },
  contentText: {
    maxWidth: "90%",
  },
  boxContainer: {
    position: "relative",
    top: "-100px",
    left: "40px",
  },
  boxProducts: {},
  boxProductItem: {
    background: "white",
    position: "relative",
    width: 241,
    height: 97, // 339 - 70
    //borderLeft:'0.2px solid rgba(229, 229, 229,0.5)',
    boxShadow: "5px 0 5px -5px rgba(0,0,0,0.3)",
    //borderRight:'0.5px solid rgba(229, 229, 229, 0.5)',
    "& .MuiTypography-h5": {
      color: "#ff4545",
      fontSize: "21px",
      textAlign: "center",
    },
    "& .MuiTypography-h4": {
      color: "#ff4545",
      fontSize: "36px",
      textAlign: "center",
    },
    "& .MuiTypography-body2": {
      textAlign: "center",
    },
    "& .MuiBox-root": {
      position: "absolute",
      top: "-55px",
      height: 339,
      width: "100%",
      zIndex: 2,
      textAlign: "center",
    },
  },
  boxUp: {
    background: "white",
    height: 121,
    width: 241,
    borderRadius: "200px 200px 0 0",
    boxShadow: "1px -3px 2px 1px rgba(0,0,0,0.1)",
    bottom: "-6px",
    position: "relative",
  },
  boxDown: {
    background: "white",
    height: 121,
    width: 241,
    borderRadius: "0 0 200px 200px",
    boxShadow: "1px 3px 2px 1px rgba(0,0,0,0.1)",
    bottom: "6px",
    position: "relative",
  },
  mediaContent: {
    width: "100%", //703,
    height: 321,
    borderRadius: 18,
  },
  ul: {
    listStyle: "none",
  },
  price: {
    fontFamily: "modulus-pro-bold",
  },
})

const moveTo = () => {
  document
    .querySelector(".boxProducts")
    .scrollIntoView({ block: "start", behavior: "smooth" })
}

const BodyHome = (props) => {
  const classes = useStyle(props)
  const { store } = props
  const {
    store_name,
    meta_description,
    store_comment,
    config_info_open_start: open_start,
    config_info_open_end: open_end,
    gallery,
    midpage_image,
  } = store

  const products = useContext(ProductsContext)

  const { price, special, special_start_date, special_end_date } =
    bestPriceProduct(products) || {}

  const bestPrice = special !== 0 ? priceFormat(special) : priceFormat(price)

  const date_start =
    special_start_date !== ""
      ? dateFormat(special_start_date, "MM/DD/YYYY")
      : open_start
  const date_end =
    special_end_date !== ""
      ? dateFormat(special_end_date, "MM/DD/YYYY")
      : open_end

  window.onscroll = () => onscroll(classes, ".price-box", 440)

  return (
    <Container className={classes.root} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <GridImages gallery={gallery} />

            <Grid container className={classes.contentText} item xs={12}>
              <Grid item md={12}>
                <Typography variant="h5">
                  {t.title_welcome_section_1}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="h3">
                  {t.title_secondary_section_1} {store_name}
                </Typography>
              </Grid>
              <Grid
                item
                md={12}
                className={`${classes.pTop} ${classes.pBottom}`}
              >
                <Typography variant="body1">{meta_description}</Typography>
              </Grid>
            </Grid>

            <GridProducts products={products} />
          </Grid>
        </Grid>
        <Hidden only="xs">
          <Grid item xs={3} md={4}>
            <Grid container item className={classes.boxContainer}>
              <div className="price-box">
                <Box className={classes.boxUp}></Box>
                <Box className={classes.boxProductItem}>
                  <Box>
                    <Typography variant="h5" component="p">
                      Du {date_start}
                    </Typography>
                    <Typography variant="h5" component="p">
                      au {date_end}
                    </Typography>
                    <Typography variant="body2" component="p">
                      à partir de
                    </Typography>
                    <Typography
                      variant="h4"
                      component="p"
                      className={classes.price}
                    >
                      {bestPrice}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {t.per_person}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={moveTo}
                      color="secondary"
                      disableElevation
                      endIcon={<PlayArrowIcon />}
                    >
                      {t.button_text}
                    </Button>
                  </Box>
                </Box>
                <Box className={classes.boxDown}></Box>
              </div>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>

      <Grid container className={classes.gridContainer}>
        <Grid container item md={8} xs={12}>
          <Grid item>
            <Typography variant="h4">{t.title_section_2}</Typography>
          </Grid>
          <Grid item className={`${classes.pTop} ${classes.pBottom}`}>
            <Typography variant="body1" component="p">
              {store_comment}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.gridContainer}>
        <Grid item md={8}>
          <InfoAddress store={store} />
        </Grid>
      </Grid>

      <GridAttribute title={t.title_highlights} />

      {midpage_image && (
        <Grid container className={`${classes.pTop} ${classes.pBottom}`}>
          <Grid item xs={12} md={8}>
            <CardMedia
              className={classes.mediaContent}
              image={midpage_image}
            ></CardMedia>
          </Grid>
        </Grid>
      )}

      <GridProductsCard
        title="Séléctionnez votre offre :"
        products={products}
      />
    </Container>
  )
}

BodyHome.propTypes = {
  store: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
}

export default BodyHome
