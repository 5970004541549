import React, { useContext, useState } from "react"
import { useHistory, Route, Switch, Redirect } from "react-router-dom"
import { Grid, Container, makeStyles } from "@material-ui/core"
import Steps from "./Steps"
import { InfoPassenger } from "../InfoPassenger/InfoPassenger"
import { OptionsVol } from "../OptionsVol/OptionsVol"
import { Payment } from "../Payment/Payment"
import ScrollToTopOnMount from "../../hooks/useScrollToTopOnMount"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import CartContext from "../../Context/CartContext"
import { getOption, searchProduct } from "../../Helpers/products"
import config from "../../Config"
import { AdditionalProductList } from "../AdditionalProductList"
import { CheckoutFormProvider } from "../CheckoutFormProvider"
import { CartContextConsumer } from "../Cart/CartContextConsumer"
import ProductsContext from "../../Context/ProductsContext"
import localStorageCache from "../../Helpers/localStorageCache"
import { formOptionsVol, FormPassengerJSON } from "../../Helpers/validation"

const useStyle = makeStyles({
  containerCheckout: {
    backgroundColor: "#eeeeee",
  },
  containerPaper: {
    ["@media (max-width: 950px)"]: {
      flexDirection: "column-reverse",
    },
  },
})

const Checkout = (props) => {
  const { cart } = useContext(CartContext)
  const products = useContext(ProductsContext)
  const classes = useStyle()
  const [step, setStep] = useState(0)

  let history = useHistory()
  let option = undefined
  const itemProducts = cart.getItems()
  if (itemProducts.length <= 0) {
    history.push("/")
    return
  }
  const item = cart.getFirstItem()
  const product = searchProduct(products, item.product_id)

  const nameOpt = config.store.product.options.additionalProducts.name

  option = getOption(product, nameOpt)

  let { passenger, contact_info, option_vol } = {}
  const quantity = cart.getTotalPersons()
  const formValues = localStorageCache.get("formValues")

  if (formValues) {
    passenger = formValues.passenger
    contact_info = formValues.contact_info
    option_vol = formValues.option_vol
  } else {
    const { passengerJSON, contactInfoJson } = FormPassengerJSON(products, item)

    //const contactInfoJson = formContactInfoJson()

    passenger = Array(quantity).fill(passengerJSON)
    contact_info = Array(quantity).fill(contactInfoJson)
    option_vol = formOptionsVol(products, item)
  }

  return (
    itemProducts.length >= 1 && (
      <CheckoutFormProvider forms={{ passenger, contact_info, option_vol }}>
        <Container className={classes.containerCheckout} maxwidth="lg">
          <ScrollToTopOnMount />
          <Grid container justify="center">
            <Grid item md={9}>
              <Steps activeStep={step} />
            </Grid>
          </Grid>
          <Grid container className={classes.containerPaper} spacing={3}>
            <Grid item xs={12} md={4}>
              <CheckoutFormContext.Consumer>
                {() => <CartContextConsumer />}
              </CheckoutFormContext.Consumer>
            </Grid>
            <Grid item xs={12} md={8}>
              <Switch>
                <Route exact path="/checkout/step-1">
                  <InfoPassenger setStep={setStep} />
                </Route>
                <Route exact path="/checkout/step-2">
                  <OptionsVol
                    setStep={setStep}
                    additionalProducts={option ? true : false}
                  />
                </Route>
                {option && (
                  <Route exact path="/checkout/step-3">
                    <AdditionalProductList setStep={setStep} option={option} />
                  </Route>
                )}
                <Route exact path="/checkout/step-end">
                  <Payment
                    setStep={setStep}
                    additionalProducts={option ? true : false}
                  />
                </Route>
                <Redirect to="/" />
              </Switch>
            </Grid>
          </Grid>
        </Container>
      </CheckoutFormProvider>
    )
  )
}

export default React.memo(Checkout)
