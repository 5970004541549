import React, { useContext } from "react"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { Button, TextField } from "@material-ui/core"
import CartContext from "../../Context/CartContext"
import ConfirmExtraDialog from "./ConfirmExtraOption"
import CheckoutFormContext from "../../Context/CheckoutFormContext"

const options = [
  { code: "br", name: "Brasil" },
  { code: "fr", name: "France" },
  { code: "co", name: "Colombia" },
  { code: "us", name: "Estados Unidos" },
]

const ITEM_HEIGHT = 48

export const MenuTransfers = ({
  label,
  value,
  setSelected,
  options,
  nameOpt,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const { cart, change, setChange } = useContext(CartContext)

  const [openD, setOpenD] = React.useState(false)
  const [opt, setOpt] = React.useState(null)

  const handleClickAccept = (option) => {
    cart.deleteExtraOption({ ...value, option: label })
    cart.addExtraOption({ ...option, option: label })
    setSelected(option)
    setChange(change + 1)
    setOpenD(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuItemClick = (option) => {
    if (option !== null) {
      setOpt(option)
      setOpenD(true)
      setAnchorEl(null)
    } else {
      cart.deleteExtraOption({ ...value, option: label })
      setSelected(null)
      setAnchorEl(null)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
      >
        AJOUTER TRANSFERT
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem key={0} onClick={() => handleMenuItemClick(null)}>
          NA
        </MenuItem>
        {options.map((option) => {
          option.option_id = options.option_id
          option.product_option_id = options.product_option_id
          option.type = options.type
          option.name_option = nameOpt
          return (
            <MenuItem
              key={option.option_value_id}
              onClick={() => handleMenuItemClick(option)}
            >
              {option.name}
            </MenuItem>
          )
        })}
      </Menu>
      {openD && (
        <ConfirmExtraDialog
          open={openD}
          setOpen={setOpenD}
          option={opt}
          setSelected={setSelected}
          setOption={handleClickAccept}
          service={nameOpt}
        />
      )}
    </div>
  )
}
