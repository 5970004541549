import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import React from 'react'

const  useStyle = makeStyles( (theme) =>({
    boxContainerHome:{
      position: 'fixed',
      left:0,
      top:0,
      width:'100%',
      height:'100%',
      zIndex:999,
      textAlign:'center',
      '& .MuiGrid-root':{
        position:'relative',
        top:'50%'
      }
    },
    boxContainer:{
        position: 'relative',
        left:0,
        top:0,
        width:'100%',
        height:'100%',
        zIndex:999,
        textAlign:'center',
        '& .MuiGrid-root':{
          position:'relative',
          top:'50%'
        }
      },
})
)

export const Loading = ({isHome}) => {
    const classes =  useStyle()
    return (
        <Box  className={ (isHome)? classes.boxContainerHome: classes.boxContainer}>
          <Grid>
            <CircularProgress />
            <h1>Chargement...</h1>
          </Grid>
      </Box>
    )
}
