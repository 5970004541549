import { Grid, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import CartContext from '../../../Context/CartContext'
import {priceFormat} from '../../../Helpers/store'

export const RenderCoupon = ({coupon}) => {
    const { cart } = useContext(CartContext)
    const discount = priceFormat(cart.getDiscount())
    const {code, name} =  coupon
    return (
        <React.Fragment  >
                <Grid container item >
                    <Grid item md={8}>
                        <Typography variant="caption">
                        {name} ({code})
                        </Typography>
                        <br/>
                        <Typography variant="caption" style={{ fontSize: 10}}>
                            coupon
                        </Typography>
                    </Grid>
                    <Grid item md={4}>
                        <Typography variant="body2" align="right">
                            - {discount}
                        </Typography>
                    </Grid>
                    <Grid></Grid>
                </Grid>
            </React.Fragment>
    )
}
