import config from "../Config"

class Cart {
  constructor(
    coupon = null,
    extraOptions = [],
    items = [],
    passengerOptions = []
  ) {
    this.coupon = coupon
    this.extraOptions = extraOptions
    this.items = items
    this.passengerOptions = []
  }

  cartCacheConfiguration(
    coupon = null,
    extraOptions = [],
    items = [],
    passengerOptions = []
  ) {
    this.coupon = coupon
    this.extraOptions = extraOptions
    this.items = items
    this.passengerOptions = []
  }

  addCoupon(coupon) {
    this.coupon = coupon
  }

  removeCoupon() {
    this.coupon = null
  }

  getCoupon() {
    return this.coupon
  }

  addExtraOption(option) {
    const exist = this.extraOptions.find((opt) => this.findOptions(opt, option))

    if (!exist) {
      return this.extraOptions.push(option)
    }
  }

  deleteExtraOption(option) {
    this.extraOptions = this.extraOptions.filter((opt) =>
      this.filterOptions(opt, option)
    )
  }

  findOptions(opt, option) {
    if (opt.position !== undefined) {
      return (
        opt.position === option.position &&
        opt.product_option_value_id === option.product_option_value_id &&
        opt.option === option.option
      )
    }
    return (
      opt.product_option_value_id === option.product_option_value_id &&
      opt.option === option.option
    )
  }

  filterOptions(opt, option) {
    if (opt.position !== undefined) {
      return !(
        opt.position === option.position &&
        opt.product_option_value_id === option.product_option_value_id &&
        opt.option === option.option
      )
    }
    return !(
      opt.product_option_value_id === option.product_option_value_id &&
      opt.option === option.option
    )
  }

  addItem(product) {
    this.items.push(product)
  }

  getSubtotal() {
    let subTotal = 0
    this.items.forEach((item) => {
      let current_price = item.special !== 0 ? item.special : item.price
      subTotal = subTotal + current_price * item.quantity * item.daysPerNight

      subTotal += item.options.price * item.quantity
    })
    return subTotal
  }

  getTotalExtraOptions() {
    let total = 0

    if (this.extraOptions.length >= 1) {
      this.extraOptions.forEach((opt) => {
        const nameOpt = config.store.product.options.insurance.name
        if (opt.name_option === nameOpt) {
          total += opt.price * this.getTotalPersons()
        } else {
          total += opt?.qty ? opt.price * opt.qty : opt.price
        }
      })
    }

    return total
  }

  getDiscount() {
    let discount = 0
    if (this.coupon) {
      switch (this.coupon.type) {
        case "P":
          discount = (this.getSubtotal() * this.coupon.discount) / 100
          break
        case "F":
          discount = parseFloat(this.coupon.discount)
          break
        default:
          break
      }
    }
    return discount
  }

  getTotals() {
    let total = 0
    if (this.items.length >= 1) {
      total += this.getSubtotal()

      total -= this.getDiscount()

      total += this.getTotalExtraOptions()
    }
    return total
  }

  getExtraOptions() {
    return this.extraOptions
  }

  getItems() {
    return this.items
  }

  getFirstItem() {
    return this.items[0] ? this.items[0] : null
  }

  getTotalPersons() {
    return this.items[0] ? this.items[0].quantity : null
  }

  reset() {
    this.extraOptions = []
    this.items = []
    this.coupon = null
  }
}

export default new Cart()
