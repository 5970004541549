import React, { useContext } from "react"
import {
  Paper,
  Grid,
  makeStyles,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Box,
  Hidden,
} from "@material-ui/core"

import Icon from "@material-ui/core/Icon"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import CartContext from "../../Context/CartContext"

const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "23ch",
    },
  },
  containerGrid: {
    padding: "0 20px",
    margin: "30px 0",
  },
  ContainerContact: {
    margin: "10px 0",
    "& .MuiPaper-root": {
      padding: "20px 0",
      textAlign: "center",
    },
    "& .MuiPaper-root.active": {
      textAlign: "center",
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
      },
    },
  },
  person: {
    cursor: "pointer",
    border: "1px solid transparent",
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Grid
      item
      md={12}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Grid>
  )
}

export const ContactInformation = ({ errors }) => {
  const classes = useStyle()

  const { cart } = useContext(CartContext)

  const {
    formValues,
    handleInputChangeContact,
    handleCheckboxChangeContact,
  } = useContext(CheckoutFormContext)

  const { passenger, contact_info } = formValues

  const number_contacts = cart.getTotalPersons()

  const renderTabs = (number_contacts, passenger) => {
    const output = []
    let name = ""
    for (var i = 0; i < number_contacts; i++) {
      name = passenger[i].firstname
        ? passenger[i].firstname
        : `Passenger ${i + 1}`
      output.push(
        <Tab
          key={i}
          icon={<Icon style={{ fontSize: 60 }}>person</Icon>}
          label={name}
        />
      )
    }
    return output
  }

  const renderTabPanels = (number_contacts, contact_info) => {
    const output = []
    const { mobile, email, contact_complete_later } = contact_info[value]
    for (var i = 0; i < number_contacts; i++) {
      output.push(
        <TabPanel key={i} value={value} index={i}>
          <Grid container item md={10} style={{ paddingBottom: 20 }}>
            <Grid item md={4} className={classes.root}>
              <TextField
                type="text"
                name="mobile"
                value={mobile}
                label="Mobile"
                onChange={(e) => handleInputChangeContact(e, value)}
                {...errors[i]?.mobile}
              />
            </Grid>
            <Grid item md={4} className={classes.root}>
              <TextField
                type="text"
                name="email"
                value={email}
                label="Adresse email"
                onChange={(e) => handleInputChangeContact(e, value)}
                {...errors[i]?.email}
              />
            </Grid>
          </Grid>

          <Grid container item md={10}>
            <Hidden xsUp>
              <FormControlLabel
                variant="caption"
                value="end"
                control={
                  <Checkbox
                    checked={contact_complete_later}
                    onChange={(e) =>
                      handleCheckboxChangeContact(
                        e,
                        "contact_complete_later",
                        value
                      )
                    }
                  />
                }
                label={
                  <Typography variant="caption">
                    Je préfère remplir ces informations plus tard
                  </Typography>
                }
                labelPlacement="end"
              />
            </Hidden>
          </Grid>
        </TabPanel>
      )
    }
    return output
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Paper elevation={3}>
      <Grid container item md={12} className={classes.containerGrid}>
        <Grid item md={12} style={{ paddingTop: 20 }}>
          <Typography variant="h4">Contact information</Typography>
        </Grid>
        <Grid item md={12} style={{ padding: "20px 0" }}>
          <Typography variant="h5">Personne à contacter</Typography>
        </Grid>
        <Grid
          container
          item
          md={10}
          spacing={2}
          className={classes.ContainerContact}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
          >
            {renderTabs(number_contacts, passenger)}
          </Tabs>
        </Grid>

        {renderTabPanels(number_contacts, contact_info)}
      </Grid>
    </Paper>
  )
}
