import React from "react"
import { Cart } from "."
import CartContext from "../../Context/CartContext"

export const CartContextConsumer = () => {
  return (
    <CartContext.Consumer>
      {({ cart }) => <Cart cart={cart} />}
    </CartContext.Consumer>
  )
}
