import React, { useContext } from "react"
import { Grid, Typography, Paper, Icon, makeStyles } from "@material-ui/core"
import { searchProduct, getOption } from "../../Helpers/products"
import CartContext from "../../Context/CartContext"
import { ErrorMessage } from "../ErrorMessage/ErrorMessage"
import config from "../../Config"
import ProductsContext from "../../Context/ProductsContext"

const useStyle = makeStyles({
  special: {
    cursor: "pointer",
    border: "1px solid transparent",
  },
})
const specials = [
  { icon: "accessible", name: "Handicap" },
  { icon: "pregnant_woman", name: "Enceinte" },
  { icon: "restaurant_menu", name: "menu végétarien" },
  { icon: "hearing", name: "sourd" },
  { icon: "child_care", name: "Enfant seul" },
  { icon: "visibilityOff", name: "Aveugle" },
  { icon: "healing", name: "Problèmes santé" },
]

export const ConditionalSpeciales = ({
  label,
  value,
  pos,
  handleMultipleOptionsPassenger,
  error,
}) => {
  const classes = useStyle()

  const { cart } = useContext(CartContext)
  const products = useContext(ProductsContext)

  let item = cart.getFirstItem()
  const nameOpt = config.store.product.options.special.name

  const product = searchProduct(products, item?.product_id)

  const options = getOption(product, nameOpt)

  const { option_value: specials } = options ? options : {}

  const handleClick = (option) => {
    handleMultipleOptionsPassenger(
      `${label}`,
      { id: [option.option_value_id], val: option },
      pos
    )
    //cart.addExtraOption(`${label}-${option.option_value_id}`, option)
    //document.querySelectorAll(`.${classes.special}`).forEach(el => el.classList.remove('active'))
    //  e.currentTarget.classList.add('active')
  }
  return (
    <>
      {specials && (
        <Grid item md={12}>
          <Typography variant="h4" style={{ paddingTop: 20 }}>
            Conditions spéciales
          </Typography>
        </Grid>
      )}
      {specials &&
        specials.map((special, index) => {
          const active = value[special.option_value_id] ? "active" : ""
          return (
            <Grid item md={4} xs={6} key={special.option_value_id}>
              <Paper
                elevation={1}
                onClick={() => handleClick(special)}
                className={`${classes.special} ${active}`}
              >
                <Icon style={{ fontSize: 30 }}>{special.icon}</Icon>
                <Typography
                  variant="caption"
                  component="p"
                  style={{ fontSize: "10pt" }}
                >
                  {special.name}
                </Typography>
              </Paper>
            </Grid>
          )
        })}
      {specials && <ErrorMessage error={error} />}
    </>
  )
}
