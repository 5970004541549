import React, { useEffect, useContext } from "react"
import Header from "../Components/Header"
import Body from "../Components/BodyHome"
import Footer from "../Components/Footer"
import { useHistory } from "react-router-dom"

export const HomeScreen = ({ store, redirect }) => {
  const { store_name, store_image, logo } = store
  const history = useHistory()

  useEffect(() => {
    let hash = history.location.hash
    if (hash !== "" && hash !== "#edit") {
      history.push("/" + hash.replace("#", ""))
    }
  }, [])

  useEffect(() => {
    if (redirect && history.location.state === undefined) {
      history.push("/checkout/step-1")
      //window.location.href = "/checkout/step-1"
    }
  }, [redirect])
  return (
    <>
      <Header title={store_name} store_image={store_image} logo={logo} />
      <Body store={store} />
      <Footer logo={logo} />
    </>
  )
}
