import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { priceFormat } from "./../../../Helpers/store"
import config from "../../../Config"

export const RenderExtraOptions = ({ options, quantity }) => {
  const nameOpt = config.store.product.options.insurance.name

  const extraOptions = options.map((option, index) => {
    const label =
      option.position !== undefined
        ? `${option.option} - Passenger ${option.position + 1}`
        : option.option
    return (
      <React.Fragment
        key={option.option + option.position + option.product_option_value_id}
      >
        <Grid container item>
          <Grid item md={8} xs={8}>
            <Typography variant="caption">{option.name}</Typography>
            <br />
            <Typography variant="caption" style={{ fontSize: 10 }}>
              {label}
            </Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <Typography variant="body2" align="right">
              {option.qty !== undefined
                ? `${priceFormat(option.price)} x ${option.qty}`
                : option.name_option === nameOpt
                ? `${priceFormat(option.price)} x ${quantity}`
                : `${priceFormat(option.price)}`}
            </Typography>
          </Grid>
          <Grid></Grid>
        </Grid>
      </React.Fragment>
    )
  })

  return <>{extraOptions}</>
}
