import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core"
import CartContext from "../../Context/CartContext"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import config from "../../Config"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function DescriptionDialog({
  product,
  value,
  option,
  children,
}) {
  const [open, setOpen] = React.useState(false)
  const [quantity, setQuantity] = React.useState(1)

  const { cart } = React.useContext(CartContext)

  const { handleAdditionalProducts } = React.useContext(CheckoutFormContext)

  const nameOpt = config.store.product.options.additionalProducts.name

  const totalPersons = cart.getTotalPersons()
  const { name, image, description, price_formated } = product

  const handleClose = () => {
    setOpen(false)
  }

  const { product_option_id, option_id, type } = option

  product.option_id = option_id
  product.product_option_id = product_option_id
  product.type = type
  product.name_option = nameOpt

  const label = "additionalProducts"
  const addOption = (product) => {
    product.qty = quantity
    cart.addExtraOption({ ...product, option: label })
    handleAdditionalProducts(label, {
      ...value,
      [product.product_option_value_id]: product,
    })
    setOpen(false)
  }

  const removeOption = (product) => {
    cart.deleteExtraOption({ ...product, option: label })
    delete value[product.product_option_value_id]
    handleAdditionalProducts(label, {
      ...value,
    })
    setOpen(false)
  }

  const renderSelect = () => {
    let menuitem = []
    for (let i = 1; i <= totalPersons; i++) {
      menuitem.push(
        <MenuItem value={i}>
          <em>{i}</em>
        </MenuItem>
      )
    }
    return menuitem
  }
  return (
    <>
      <Grid item md={4} onClick={() => setOpen(true)}>
        {children}
      </Grid>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"La description"}
        </DialogTitle>
        <DialogContent>
          <Grid container align="center">
            <Grid item md={12} xs={12}>
              <img src={image} alt={name} width="40%" />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography>{description}</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography>
                <strong
                  style={{ fontFamily: "modulus-pro-bold", fontSize: 18 }}
                >
                  {price_formated}
                </strong>
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl>
                <InputLabel shrink id="quantity">
                  Quantité
                </InputLabel>
                <Select
                  value={quantity}
                  name="quantity"
                  labelId="quantity"
                  onChange={(e) => {
                    console.log(e.target.value)
                    setQuantity(e.target.value)
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {renderSelect()}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fermer
          </Button>
          {value[product.product_option_value_id] ? (
            <Button
              variant="outlined"
              color="secondary"
              disableElevation
              onClick={() => removeOption(product)}
            >
              Enlever
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              disableElevation
              onClick={() => addOption(product)}
            >
              AJOUTER
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
