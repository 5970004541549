import React from "react"
import PropTypes from "prop-types"
import { Grid } from "@material-ui/core"
import ProductItem from "../ProductItem"
import { priceFormat } from "../../Helpers/store"

GridProducts.propTypes = {
  products: PropTypes.array.isRequired,
}

function GridProducts({ products }) {
  const renderProducts = () =>
    products.map((product) => (
      <Grid item xs={12} md={4} key={product.id}>
        <ProductItem
          id={product.id}
          title={product.name}
          img={product.original_image}
          description={product.meta_title}
          price={`${priceFormat(product.price)}`}
        />
      </Grid>
    ))

  return (
    <Grid container item xs={12} md={12} spacing={4}>
      {renderProducts()}
    </Grid>
  )
}

export default GridProducts
