import React from 'react'
import { Typography, Grid } from '@material-ui/core'

export const ErrorMessage = ({error}) => {
    return (
        <>
        {
            error?.error &&
            <Grid container item md={12}>
                <Typography variant="caption" color="secondary">
                    {error?.helperText}
                </Typography>
            </Grid>
            
        }
        </>
    )
}
