import React, { useContext, useState } from "react"
import {
  Paper,
  Grid,
  Typography,
  Icon,
  makeStyles,
  Button,
} from "@material-ui/core"
import CartContext from "../../Context/CartContext"
import { searchProduct, getOption } from "../../Helpers/products"
import { MenuTransfers } from "./MenuTransfers"
import { useEffect } from "react"
import config from "../../Config"
import ProductsContext from "../../Context/ProductsContext"

const useStyle = makeStyles({
  containerGrid: {
    padding: "0 20px",
    marginBottom: 30,
  },
  containerPadding: {
    padding: "20px 0",
  },
  button: {
    borderRadius: 6,
  },
})

export const AddTransfer = ({ value, handleCheckboxOptions }) => {
  const classes = useStyle()

  const { cart } = useContext(CartContext)
  const products = useContext(ProductsContext)

  console.log("value", value)

  const [depart, setDepart] = useState(value.depart)
  const [returnT, setReturnT] = useState(value.returnT)

  useEffect(() => {
    handleCheckboxOptions("transfers", { ...value, depart: depart })
  }, [depart])

  useEffect(() => {
    handleCheckboxOptions("transfers", { ...value, returnT: returnT })
  }, [returnT])

  let item = cart.getFirstItem()

  const nameOpt = config.store.product.options.transfer.name

  const product = searchProduct(products, item?.product_id)

  const options = getOption(product, nameOpt)

  const {
    option_id,
    product_option_id,
    type,
    option_value: transfers,
  } = options ? options : {}

  transfers.option_id = option_id
  transfers.product_option_id = product_option_id
  transfers.type = type

  return (
    <>
      {transfers && (
        <Paper elevation={3} className={classes.containerGrid}>
          <Grid container item md={10} className={classes.containerPadding}>
            <Grid item md={12} xs={12}>
              <Typography variant="h4">Ajouter transfert</Typography>
            </Grid>
            <Grid item md={12} style={{ padding: "20px 0" }}>
              <Typography variant="h5">Vol aller</Typography>
            </Grid>
            <Grid container item md={12}>
              <Grid container item md={12}>
                <Grid container item md={6} alignItems="center">
                  <Typography variant="caption">
                    {depart ? depart.name : "Pas de transfert"}
                  </Typography>
                </Grid>
                <Grid container item md={6} alignItems="center">
                  <Grid item md={5}></Grid>
                  <Grid item md={7}>
                    <MenuTransfers
                      label="depart"
                      value={depart}
                      setSelected={setDepart}
                      options={transfers}
                      nameOpt={nameOpt}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12} xs={12} style={{ padding: "20px 0" }}>
                <Typography variant="h5">Vol retour</Typography>
              </Grid>
              <Grid container item md={12}>
                <Grid container item md={12}>
                  <Grid container item md={6} alignItems="center">
                    <Typography variant="caption">
                      {returnT ? returnT.name : "Pas de transfert"}
                    </Typography>
                  </Grid>
                  <Grid container item md={6} alignItems="center">
                    <Grid item md={5}></Grid>
                    <Grid item md={7}>
                      <MenuTransfers
                        label="return"
                        value={returnT}
                        setSelected={setReturnT}
                        options={transfers}
                        nameOpt={nameOpt}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  )
}
