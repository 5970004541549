import React from "react"
import { Grid, Chip, Typography, makeStyles } from "@material-ui/core"
import WorkIcon from "@material-ui/icons/Work"
import CheckIcon from "@material-ui/icons/Check"

const useStyle = makeStyles((theme) => ({
  muiChip: {
    marginTop: "10px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "white",
    "& .MuiChip-label": {
      fontFamily: "modulus-pro-semi-bold",
    },
  },
  baggageContainer: {
    padding: "10px",
    width: "85%",
    margin: "10px 0",
    cursor: "pointer",
    border: "1px solid transparent",
  },
  active: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}))

export const FreeBaggage = ({ flight }) => {
  const classes = useStyle()

  const { cabin_luggage, checked_luggage } = flight
  return (
    <>
      {cabin_luggage != 0 && (
        <Grid container item md={12} alignItems="flex-end">
          <Grid item md={2} xs={6}>
            <Chip
              className={classes.muiChip}
              size="small"
              label={
                <Typography component="span" style={{ fontSize: "10pt" }}>
                  <CheckIcon
                    color="secondary"
                    style={{ top: "3px", position: "relative" }}
                  />
                  Gratuit
                </Typography>
              }
            />
          </Grid>
          <Grid item md={10}>
            <WorkIcon style={{ fontSize: 32, top: 6, position: "relative" }} />
            <Typography
              variant="h5"
              component="span"
              style={{ fontSize: "13pt" }}
            >
              Bagage Cabine
            </Typography>
          </Grid>
        </Grid>
      )}

      {checked_luggage != 0 && (
        <Grid container item md={12} alignItems="flex-end">
          <Grid item md={2} xs={6}>
            <Chip
              className={classes.muiChip}
              size="small"
              label={
                <Typography component="span" style={{ fontSize: "10pt" }}>
                  <CheckIcon
                    color="secondary"
                    style={{ top: "3px", position: "relative" }}
                  />
                  Gratuit
                </Typography>
              }
            />
          </Grid>
          <Grid item md={10}>
            <WorkIcon style={{ fontSize: 32, top: 6, position: "relative" }} />
            <Typography
              variant="h5"
              component="span"
              style={{ fontSize: "13pt" }}
            >
              Bagage en soute
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}
