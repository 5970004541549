import React, { useContext } from "react"
import {
  Paper,
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import CartContext from "../../../Context/CartContext"
import { searchProduct, getOption } from "../../../Helpers/products"
import GetInformation from "./GetInformation"
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage"
import config from "../../../Config"
import ProductsContext from "../../../Context/ProductsContext"

const useStyle = makeStyles({
  containerGrid: {
    padding: "0 20px",
    marginBottom: 30,
    width: "100%",
  },
  containerPadding: {
    padding: "20px 0",
  },
  button: {
    borderRadius: 6,
  },
})

const Insurances = ({ value, handleCheckboxOptions, error }) => {
  const classes = useStyle()
  const { cart } = useContext(CartContext)
  const products = useContext(ProductsContext)
  const [option, setOption] = React.useState("")
  let item = cart.getFirstItem()

  const nameOpt = config.store.product.options.insurance.name

  const product = searchProduct(products, item?.product_id)

  const options = getOption(product, nameOpt)

  const {
    option_id,
    product_option_id,
    type,
    option_value: insurances,
  } = options ? options : {}

  const handleChange = (event) => {
    const label = "insurance"

    setOption(event.target.value)
    const selected = insurances.find(
      (insurance) => insurance.option_value_id == event.target.value
    )

    if (value) {
      const [id] = Object.keys(value)
      cart.deleteExtraOption({ ...value[id], option: label })
    }

    handleCheckboxOptions("insurances", {
      [selected.option_value_id]: selected,
    })
    cart.addExtraOption({ ...selected, option: label })
    /*if (e.target.checked) {
      handleCheckboxOptions("insurances", {
        ...value,
        [insurance.option_value_id]: insurance,
      })
      
    } else {
      let insurances = { ...value }
      delete insurances[insurance.option_value_id]
      handleCheckboxOptions("insurances", { ...insurances })
      cart.deleteExtraOption({ ...insurance, option: label })
    }*/
  }

  return (
    insurances && (
      <Grid container item md={12}>
        <Paper elevation={3} className={classes.containerGrid}>
          <Grid container item md={12} className={classes.containerPadding}>
            <Grid item md={12}>
              <Typography variant="h4">Assurance</Typography>
            </Grid>
            <RadioGroup
              aria-label="insurance"
              name="insurance"
              value={option}
              onChange={handleChange}
              style={{ width: "100%" }}
            >
              <Grid container>
                {insurances.map((insurance) => {
                  insurance.option_id = option_id
                  insurance.product_option_id = product_option_id
                  insurance.type = type
                  insurance.name_option = nameOpt
                  return (
                    <React.Fragment key={insurance.option_value_id}>
                      <Grid
                        container
                        item
                        md={4}
                        style={{ padding: "20px 0" }}
                        alignItems="center"
                      >
                        <Typography variant="h5">{insurance.name}</Typography>
                      </Grid>
                      <Grid item md={4} style={{ padding: "20px 0" }}>
                        <FormControlLabel
                          value={insurance.option_value_id}
                          control={
                            <Radio
                              checked={
                                value[insurance.option_value_id] ? true : false
                              }
                            />
                          }
                          label={`${insurance.price_formated}`}
                          labelPlacement="start"
                        />
                      </Grid>
                      <GetInformation name={insurance.name} />
                    </React.Fragment>
                  )
                })}
                {/*insurances.map((insurance) => {
                insurance.option_id = option_id
                insurance.product_option_id = product_option_id
                insurance.type = type
                return (
                  <React.Fragment key={insurance.option_value_id}>
                    <Grid
                      container
                      item
                      md={4}
                      style={{ padding: "20px 0" }}
                      alignItems="center"
                    >
                      <Typography variant="h5">{insurance.name}</Typography>
                    </Grid>
                    <Grid item md={4} style={{ padding: "20px 0" }}>
                      <FormControlLabel
                        value={insurance.option_value_id}
                        control={<Radio />}
                        label={insurance.price_formated}
                        labelPlacement="start"
                      />
                    </Grid>
                    <GetInformation name={insurance.name} />
                  </React.Fragment>
                )
              })*/}
              </Grid>
            </RadioGroup>

            <ErrorMessage error={error} />
          </Grid>
        </Paper>
      </Grid>
    )
  )
}

export default React.memo(Insurances)
