import React, { useEffect } from "react"
import {
  Paper,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Radio,
  Checkbox,
  FormControlLabel,
  Chip,
  TextareaAutosize,
  getContrastRatio,
} from "@material-ui/core"
import { Civilite } from "./Civilite"
import { Nationality } from "./Nationality"
import InfoIcon from "@material-ui/icons/Info"
import DatePickerInput from "./DatePickerInput"
import BaggageList from "./BaggageList/BaggageList"
import { Loyalty } from "./Loyalty"
import { ConditionalSpeciales } from "./ConditionalSpeciales"
import { useContext } from "react"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import { StoreContext } from "../../Context/StoreContext"
import { focusOnError } from "../../Helpers/utils"

const useStyle = makeStyles((theme) => ({
  containerGrid: {
    padding: "0 20px",
  },
  containerProduct: {
    padding: "0 20px",
  },
  containerForm: {
    paddingTop: 20,
  },
  containerFidelity: {
    padding: "0 20px",
    "& .MuiGrid-grid-md-4": {
      maxWidth: "24%",
      ["@media (max-width: 700px)"]: {
        maxWidth: "100%",
      },
    },
    "& .MuiPaper-root": {
      padding: "10px 0",
      textAlign: "center",
      "& .MuiSvgIcon-root": {
        color: "#c3c3c3",
      },
    },
    "& .MuiPaper-root.active": {
      padding: "10px 0",
      textAlign: "center",
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
      },
    },
  },
  hrColor: {
    height: 1,
    border: 0,
    backgroundColor: "#eeeeee",
  },
  muiChip: {
    margin: "10px 0",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "white",
    "& .MuiChip-label": {
      fontFamily: "modulus-pro-semi-bold",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(2),
      width: "28ch",
      ["@media (max-width: 700px)"]: {
        margin: 0,
      },
    },
  },
  mdCvilite: {
    maxWidth: "20%",
  },
}))

export const Passenger = ({ pos, errors }) => {
  const classes = useStyle()
  const { config_form_fill_later } = useContext(StoreContext)
  const {
    formValues,
    handleInputChange,
    handleInputDate,
    handleInputWithOnChange,
    handleInputChangePassenger,
    handleCheckboxChangePassenger,
    handleMultipleOptionsPassenger,
  } = useContext(CheckoutFormContext)
  const { passenger, option_vol } = formValues
  const {
    sex,
    firstname,
    lastname,
    nationality,
    dob,
    passport_number,
    passport_country,
    passport_emission_date,
    passport_expiration_date,
    passport_place_of_emission,
    passport_complete_later,
    loyalty_program,
    luggage_depart,
    luggage_return,
    special_needs,
    comment,
  } = passenger[pos]

  const title = option_vol.transfers ? "Passenger" : "Client"
  return (
    <Paper elevation={3} style={{ marginBottom: 30 }}>
      <Grid container item md={12} className={classes.containerGrid}>
        <Grid item md={12} style={{ paddingTop: 20 }}>
          <Typography variant="h4">
            {title} {pos + 1}
          </Typography>
        </Grid>

        <Grid container item md={12} className={classes.containerForm}>
          <Grid item md={2} xs={12}>
            <Civilite
              label="sex"
              value={sex}
              pos={pos}
              handleInputChangePassenger={handleInputChangePassenger}
              error={errors?.sex}
            />
          </Grid>
          <Grid item className={classes.root} md={8}>
            <TextField
              name="firstname"
              value={firstname}
              label="Nom"
              required={true}
              size="medium"
              onChange={(e) => handleInputChangePassenger(e, pos)}
              {...errors?.firstname}
            />
            <TextField
              name="lastname"
              value={lastname}
              label="Prénom"
              required={true}
              onChange={(e) => handleInputChangePassenger(e, pos)}
              {...errors?.lastname}
            />
          </Grid>
          <Grid item md={2}>
            <Nationality
              label="nationality"
              value={nationality}
              handleInputWithOnChange={handleInputWithOnChange}
              pos={pos}
              handleInputChangePassenger={handleInputChangePassenger}
            />
          </Grid>
        </Grid>

        <Grid container item md={10}>
          <Grid container item md={12}>
            <DatePickerInput
              label="Date de naissance"
              name="dob"
              value={dob}
              handleInputDate={handleInputDate}
              pos={pos}
              handleInputChangePassenger={handleInputChangePassenger}
              isValidated={pos === 0 ? true : false}
            />
          </Grid>
          <Grid container item md={1}>
            <InfoIcon style={{ color: "#c3c3c3", fontSize: 40 }} />
          </Grid>
          <Grid item md={9}>
            <Typography variant="caption">
              Assurez-vous d’entrer votre nom de la même manière dont il
              apparait sur votre passeport. Vous aurez peut-être à payer des
              frais si certaines informations sont erronnées
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {passport_country && (
        <>
          <Grid
            container
            item
            md={10}
            className={classes.containerGrid}
            alignItems="flex-end"
          >
            <Grid item md={12}>
              <Typography variant="h4" style={{ paddingTop: 20 }}>
                Informations Passeport
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Nationality
                label="passport_country"
                value={passport_country}
                handleInputWithOnChange={handleInputWithOnChange}
                pos={pos}
                handleInputChangePassenger={handleInputChangePassenger}
              />
            </Grid>
            <Grid item md={10} className={classes.root}>
              <TextField
                type="text"
                name="passport_number"
                value={passport_number}
                onChange={(e) => handleInputChangePassenger(e, pos)}
                label="Numéro Passeport"
                size="medium"
                {...errors?.passport_number}
              />
            </Grid>
          </Grid>

          <Grid container item md={10} className={classes.containerGrid}>
            <Grid container item md={4}>
              <DatePickerInput
                label="Date d’émission"
                name="passport_emission_date"
                value={passport_emission_date}
                handleInputDate={handleInputDate}
                pos={pos}
                handleInputChangePassenger={handleInputChangePassenger}
              />
            </Grid>
            <Grid container item md={4}>
              <DatePickerInput
                label="Date d’expiration"
                name="passport_expiration_date"
                value={passport_expiration_date}
                handleInputDate={handleInputDate}
                pos={pos}
                handleInputChangePassenger={handleInputChangePassenger}
              />
            </Grid>
            <Grid item md={4} className={classes.root}>
              <TextField
                type="text"
                name="passport_place_of_emission"
                value={passport_place_of_emission}
                label="Lieu d’émission"
                onChange={(e) => handleInputChangePassenger(e, pos)}
                {...errors?.passport_place_of_emission}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            md={10}
            className={classes.containerGrid}
            style={config_form_fill_later == 1 ? {} : { display: "none" }}
          >
            <FormControlLabel
              variant="caption"
              value="end"
              control={
                <Checkbox
                  checked={passport_complete_later}
                  onChange={(e) =>
                    handleCheckboxChangePassenger(
                      e,
                      "passport_complete_later",
                      pos
                    )
                  }
                />
              }
              label={
                <Typography variant="caption">
                  Je préfère remplir ces informations plus tard
                </Typography>
              }
              labelPlacement="end"
            />
          </Grid>
        </>
      )}

      {loyalty_program && (
        <Grid container item md={10} className={classes.containerFidelity}>
          <Loyalty
            value={loyalty_program}
            pos={pos}
            handleInputChangePassenger={handleInputChangePassenger}
            error={errors?.loyalty_program}
          />
        </Grid>
      )}

      {luggage_depart && (
        <Grid
          container
          item
          md={10}
          className={classes.containerGrid}
          alignItems="center"
        >
          <BaggageList
            title="Bagage (vol aller)"
            id="aller"
            label="luggage_depart"
            value={luggage_depart}
            pos={pos}
            handleMultipleOptionsPassenger={handleMultipleOptionsPassenger}
            error={errors?.luggage_depart}
          />
        </Grid>
      )}
      {luggage_return && (
        <Grid
          container
          item
          md={10}
          className={classes.containerGrid}
          alignItems="center"
        >
          <BaggageList
            title="Bagage (vol retour)"
            id="retour"
            label="luggage_return"
            value={luggage_return}
            pos={pos}
            handleMultipleOptionsPassenger={handleMultipleOptionsPassenger}
            error={errors?.luggage_return}
          />
        </Grid>
      )}

      {special_needs && (
        <Grid
          container
          item
          md={10}
          className={classes.containerFidelity}
          spacing={3}
        >
          <ConditionalSpeciales
            label="special_needs"
            value={special_needs}
            pos={pos}
            handleMultipleOptionsPassenger={handleMultipleOptionsPassenger}
            error={errors?.special_needs}
          />
        </Grid>
      )}

      <Grid container item md={10} className={classes.containerFidelity}>
        <Grid item md={12}>
          <Typography variant="h4" style={{ paddingTop: 20 }}>
            Commentaire additionnel
          </Typography>
        </Grid>
        <TextareaAutosize
          rowsMax={4}
          style={{ width: "100%", height: 120, margin: "20px 0" }}
          aria-label="maximum height"
          placeholder="Commentaire additionnel"
          name="comment"
          value={comment}
          onChange={(e) => handleInputChangePassenger(e, pos)}
        />
      </Grid>
    </Paper>
  )
}
