import React from "react"

import { Grid, Paper, Typography } from "@material-ui/core"

export function AdditionalProduct({ product, option, value, handleClick }) {
  return (
    <Grid
      container
      key={product.product_option_value_id}
      item
      md={12}
      style={{ textAlign: "center" }}
    >
      <Grid item md={12}>
        <Paper style={{ cursor: "pointer" }}>
          <Grid item md={12} xs={12}>
            <img src={product.image} alt={product.name} width="50%" />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="caption">{product.name}</Typography>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
