import { useState } from "react"

export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState)

  const handleInputChange = (e) => {
    const target = e.target
    setValues({
      ...values,
      [target.name]: target.value,
    })
  }

  const handleInputChangePassenger = (e, pos) => {
    var target = {}
    if (e.target) {
      target = e.target
    } else {
      target = e
    }

    const passenger = [...values.passenger]
    passenger[pos] = { ...passenger[pos], [target.name]: target.value }
    setValues({
      ...values,
      passenger,
    })
  }

  const handleCheckboxChangePassenger = (e, name, pos) => {
    const target = e.target
    const passenger = [...values.passenger]
    passenger[pos] = { ...passenger[pos], [name]: target.checked }
    setValues({
      ...values,
      passenger,
    })
  }

  const handleMultipleOptionsPassenger = (name, value, pos) => {
    const passenger = [...values.passenger]

    let field = passenger[pos][name]

    if (field[value.id]) {
      delete field[value.id]
    } else {
      field = { ...field, [value.id]: value.val }
    }

    passenger[pos] = { ...passenger[pos], [name]: field }
    setValues({
      ...values,
      passenger,
    })
  }

  const handleInputChangeContact = (e, pos) => {
    var target = {}
    if (e.target) {
      target = e.target
    } else {
      target = e
    }

    const contact_info = [...values.contact_info]
    contact_info[pos] = { ...contact_info[pos], [target.name]: target.value }
    setValues({
      ...values,
      contact_info,
    })
  }

  const handleCheckboxChangeContact = (e, name, pos) => {
    const target = e.target
    const contact_info = [...values.contact_info]
    contact_info[pos] = { ...contact_info[pos], [name]: target.checked }
    setValues({
      ...values,
      contact_info,
    })
  }

  const handleInputWithOnChange = (field) => {
    setValues({
      ...values,
      [field.name]: field.value,
    })
  }

  const handleInputDate = (date) => {
    setValues({
      ...values,
      [date.name]: date.value,
    })
  }

  const reset = () => {
    setValues(initialState)
  }

  const handleCheckboxOptions = (name, value) => {
    let option_vol = { ...values.option_vol, [name]: { ...value } }
    setValues({
      ...values,
      option_vol,
    })
  }

  const handleCheckboxConditional = (name, value) => {
    let option_vol = { ...values.option_vol, [name]: value }
    setValues({
      ...values,
      option_vol,
    })
  }

  const handleAdditionalProducts = (name, value) => {
    let option_vol = { ...values.option_vol, [name]: { ...value } }
    setValues({ ...values, option_vol })
  }

  return {
    formValues: values,
    handleInputChange,
    handleInputDate,
    handleInputWithOnChange,
    reset,
    handleInputChangePassenger,
    handleCheckboxChangePassenger,
    handleMultipleOptionsPassenger,
    handleInputChangeContact,
    handleCheckboxChangeContact,
    handleCheckboxOptions,
    handleCheckboxConditional,
    handleAdditionalProducts,
  }
}
