import React, { useContext } from "react"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import PersonIcon from "@material-ui/icons/Person"
import FlightIcon from "@material-ui/icons/Flight"
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import StepConnector from "@material-ui/core/StepConnector"
import Typography from "@material-ui/core/Typography"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import CartContext from "../../Context/CartContext"
import { getOption, searchProduct } from "../../Helpers/products"
import config from "../../Config"
import ProductsContext from "../../Context/ProductsContext"

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 38,
    ["@media (max-width: 500px)"]: {
      top: 20,
    },
  },
  active: {
    "& $line": {
      borderColor: "#eaeaf0",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#eaeaf0",
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "#d8d8d8",
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "white",
    zIndex: 1,
    color: "#c3c3c3",
    width: 70,
    height: 70,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    ["@media (max-width: 500px)"]: {
      width: 40,
      height: 40,
      "& .MuiSvgIcon-root": {
        fontSize: "20px !important",
      },
    },
  },
  active: {
    backgroundColor: "#ff4545",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
  completed: {
    backgroundColor: "white",
    "& .MuiSvgIcon-root": {
      color: "#333333",
    },
  },
})

const ExistAdditionalProducts = () => {
  const { cart } = useContext(CartContext)
  const products = useContext(ProductsContext)
  const item = cart.getFirstItem()

  const product = searchProduct(products, item.product_id)
  const nameOpt = config.store.product.options.additionalProducts.name
  const option = getOption(product, nameOpt)

  return option
}

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const { formValues } = useContext(CheckoutFormContext)

  const { option_vol } = formValues

  let icons = {
    1: <ShoppingCartIcon style={{ fontSize: 45 }} />,
    2: <PersonIcon style={{ fontSize: 45 }} />,
  }

  let NStep = 3
  icons[NStep] = option_vol.transfers ? (
    <FlightIcon style={{ fontSize: 45 }} />
  ) : (
    <AddShoppingCartIcon style={{ fontSize: 45 }} />
  )
  const option = ExistAdditionalProducts()
  if (option) {
    NStep += 1
    icons[NStep] = <AddCircleOutlineIcon style={{ fontSize: 45 }} />
  }

  icons[NStep + 1] = <CreditCardIcon style={{ fontSize: 45 }} />
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 25,
    "& .MuiStepper-root": {
      backgroundColor: "transparent",
      "& .MuiStepLabel-label": {
        color: "rgba(0, 0, 0, 0.87)",
      },
      "& .MuiStepLabel-label.MuiStepLabel-active": {
        color: "#ff4545",
      },
    },
    ["@media (max-width: 500px)"]: {
      padding: 0,
      "& .MuiStepLabel-label": {
        fontSize: 9,
      },
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

function getSteps(option_vol) {
  const step2 = option_vol.transfers ? "INFOS PASSAGER" : " INFOS CLIENT"
  const step3 = option_vol.transfers ? "OPTIONS VOL" : "OPTIONS"

  let steps = ["PANIER", step2, step3]

  const option = ExistAdditionalProducts()

  if (option) {
    steps.push("PRODUITS ADDITIONNELS")
  }
  steps.push("PAIEMENT")

  return steps
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Select campaign settings..."
    case 1:
      return "What is an ad group anyways?"
    case 2:
      return "This is the bit I really care about!"
    default:
      return "Unknown step"
  }
}

export default function Steps({ activeStep }) {
  const classes = useStyles()
  const { formValues } = useContext(CheckoutFormContext)
  const { option_vol } = formValues

  const steps = getSteps(option_vol)

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ display: "none" }}>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}
