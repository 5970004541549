export const onscroll = (classes, selector, height) => {
  if (document.querySelector(`${selector}`)) {
    const ElemCart = document.querySelector(`${selector}`)
    if (document.body.clientWidth < 1220) {
      ElemCart.classList.remove(`${classes.fixedTop}`)
      return
    }

    let distance = ElemCart.offsetTop - document.documentElement.scrollTop
    if (distance < 0) {
      ElemCart.classList.add(`${classes.fixedTop}`)
    }
    if (document.documentElement.scrollTop < height) {
      ElemCart.classList.remove(`${classes.fixedTop}`)
    }
  }
}

export const resizeBody = (classes, selector) => {
  if (document.querySelector(`${selector}`)) {
    const ElemCart = document.querySelector(`${selector}`)
    if (document.body.clientWidth < 1220) {
      ElemCart.classList.remove(`${classes.fixedTop}`)
      return
    }
  }
}

export const scrollToBody = () => {
  document.body.scrollIntoView({ block: "start", behavior: "smooth" })
}
