import config from "./../Config"
import localStorageCache from "./localStorageCache"
import { differenceInDays } from "date-fns/esm"

class Ajax {
  constructor() {
    this.baseUrl = config.store.opencartUrl
    this.auth = config.store.ocAuth
    this.options = {
      "X-Oc-Store-Id": config.store.id,
    }
  }

  getUrl(path = "") {
    return this.baseUrl + path
  }

  getLocalStorageExist() {
    if (localStorage.getItem("oc_token")) {
      const json = JSON.parse(localStorage.getItem("oc_token"))
      const days = differenceInDays(new Date(), new Date(json.timestamp))
      if (days >= 2) {
        localStorage.removeItem("oc_token")
        return false
      }
      return true
    }

    if (!localStorage.getItem("oc_token")) {
      return false
    }
  }

  getCountry() {
    const path = "index.php?route=feed/rest_api/countries"
    return this._reqOC(path, "GET", null)
  }

  getInformationPages() {
    const path = "index.php?route=feed/rest_api/information"

    return this._reqOC(path, "GET", null)
  }

  getInformationPageByMetaTitle(meta_title) {
    const path = "index.php?route=feed/rest_api/getInformationByMetaTitle"
    return this._reqOC(path, "GET", { meta_title: meta_title })
  }

  getStore() {
    const path = "index.php?route=feed/rest_api/stores&id=" + config.store.id

    return this._reqOC(path, "GET", null)
  }

  getAttributeByName(name) {
    const path = "index.php?route=feed/rest_api/attribute"
    return this._reqOC(path, "GET", { name: name })
  }

  getCapsuls(id = null) {
    const path = "index.php?route=feed/rest_api/capsuls/"

    if (id) {
      return this._reqOC(path, "GET", { id: id })
    } else {
      return this._reqOC(path, "GET", null)
    }
  }

  validateCoupon(data) {
    const path = "/index.php?route=rest/coupon/coupon"
    return this._reqOC(path, "POST", data)
  }

  getSession(email) {
    const path = "/index.php?route=rest/session/session"
    return this._reqOC(path, "GET", { email })
  }

  sendSession(data) {
    const path = "/index.php?route=rest/session/session"
    return this._reqOC(path, "POST", data)
  }

  getProducts() {
    const path = "index.php?route=feed/rest_api/products"
    return this._reqOC(path, "GET", null)
  }

  senData(data) {
    const path = "/index.php?route=feed/rest_api/addOrder"
    //const path = "/index.php?route=rest/order/addorder"
    return this._reqOC(path, "POST", data)
  }

  sendTransaction(data) {
    const path = "index.php?route=feed/rest_api/addOrdertransaction"
    //const path = "index.php?route=rest/order/addOrdertransaction"
    return this._reqOC(path, "POST", data)
  }

  sendContact(data) {
    const path = "index.php?route=rest/contact/sendContact"
    return this._reqOC(path, "POST", data)
  }

  _reqOC(path, method, data) {
    let url = this.getUrl(path)

    let req_data = null
    if (method === "POST") {
      req_data = data
    } else {
      for (let key in data) {
        url += "&" + key + "=" + data[key]
      }
    }

    let authorization = null
    if (!localStorage.getItem("oc_token")) {
      authorization = `Basic ${this.auth.username}`
    } else {
      authorization = `Bearer ${localStorageCache.get("oc_token")}`
    }
    const headers = new Headers({
      ...this.options,
      Authorization: authorization,
      "Content-Type": "application/json",
    })

    const initial = {
      method: method,
      body: req_data,
      headers,
    }

    return { url, initial }
  }

  getToken() {
    const path =
      "index.php?route=feed/rest_api/gettoken&grant_type=client_credentials"
    const { url, initial } = this._reqOC(path, "POST", null)
    const fetchData = async () => {
      try {
        const response = await fetch(url, initial)
        const data = await response.json()
        localStorageCache.set("oc_token", data.data.access_token)
        return data
      } catch (err) {
        console.error(err)
      }
    }
    const data = fetchData()
    return data
  }
}

export default new Ajax()
