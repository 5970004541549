import React, { useEffect, useContext, useState } from "react"
import { Grid, Button } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { Passenger } from "../InfoPassenger/Passenger"
import { ContactInformation } from "../InfoPassenger/ContactInformation"

import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import CartContext from "../../Context/CartContext"
import CheckoutFormContext from "../../Context/CheckoutFormContext"
import {
  validatePassenger,
  validateContactInfo,
} from "../../Helpers/validation"
import isEmpty from "lodash/isEmpty"
import { scrollToBody } from "../../Helpers/utils"
import localStorageCache from "../../Helpers/localStorageCache"
import { saveSession } from "../../Helpers/session"
import ajax from "../../Helpers/ajax"

export const InfoPassenger = React.memo(({ setStep }) => {
  const { cart, email } = useContext(CartContext)
  const { formValues } = useContext(CheckoutFormContext)

  const { passenger, contact_info } = formValues
  const [errors, setErrors] = useState({ passenger: [], contact_info: [] })
  const {
    passenger: errors_in_passenger,
    contact_info: errors_in_contact_info,
  } = errors
  const renderPassanger = []
  for (var i = 0; i < cart.getTotalPersons(); i++) {
    renderPassanger.push(
      <Passenger
        key={i}
        passenger={passenger[i]}
        pos={i}
        errors={errors_in_passenger[i] ? errors_in_passenger[i] : []}
      />
    )
  }

  useEffect(() => {
    setStep(1)
  })

  useEffect(() => {
    scrollToBody()
  }, [errors])

  let history = useHistory()

  const handleBack = () => {
    cart.reset()
    history.push("/")
  }

  const handleNext = () => {
    let errors = {}
    errors.passenger = validatePassenger(passenger)
    errors.contact_info = validateContactInfo(contact_info)
    if (
      Object.entries(errors.passenger[0]).length !== 0 ||
      Object.entries(errors.contact_info[0]).length !== 0
    ) {
      setErrors(errors)
      return
    }

    saveSession(email, { formValues, cart })
    history.push("/checkout/step-2")
  }

  return (
    <>
      {renderPassanger}
      {!isEmpty(contact_info[0]) && (
        <ContactInformation errors={errors_in_contact_info} />
      )}

      <Grid container item md={12}>
        <Grid item md={6} xs={6}>
          <Button variant="contained" onClick={handleBack} disableElevation>
            RETOUR AU PANIER
          </Button>
        </Grid>
        <Grid container item md={6} xs={6} justify="flex-end">
          <Button
            variant="contained"
            onClick={handleNext}
            color="secondary"
            disableElevation
            endIcon={<PlayArrowIcon />}
          >
            Etape suivante
          </Button>
        </Grid>
      </Grid>
    </>
  )
})
