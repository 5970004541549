import clsx from "clsx";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import React, { Component } from "react";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import { DatePicker } from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
import MomentUtils from '@date-io/moment';
import moment from "moment";
// this guy required only on the docs site to work with dynamic date library
//import { makeJSDateObject } from "../../../utils/helpers";
import { IconButton, withStyles } from "@material-ui/core";
import { addDays } from "date-fns/esm";
import { isBefore } from "date-fns";
import { isAfter } from "date-fns"

const makeJSDateObject = (date) =>{
    return moment(date).clone().toDate()
}

class DateRangePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: this.props.selectedDate,
            days: this.props.days,
            handleDateChange: this.props.handleDateChange
          };
      }


      static getDerivedStateFromProps(props, state) {
        if (props.selectedDate !== state.selectedDate) {
          return {
            selectedDate: props.selectedDate,
          };
        }
    
        // Return null if the state hasn't changed
        return null;
      }

//   handleWeekChange = date => {
//     this.setState({ selectedDate: makeJSDateObject(date) });
//   };

  formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = makeJSDateObject(date);

    return dateClone && isValid(dateClone)
      ? `Week of ${format(startOfWeek(dateClone), "MMM do")}`
      : invalidLabel;
  };

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes, selectedDate:arrayDate } = this.props;
    let dateClone = makeJSDateObject(date);
    //let selectedDateClone = makeJSDateObject(selectedDate);
    const [ dateCloneStart, dateCloneEnd ] = arrayDate
    //console.log(dateCloneStart, dateCloneEnd)
    const start = makeJSDateObject(dateCloneStart);
    const end = makeJSDateObject(dateCloneEnd);

    const disablePastDay = isBefore(dateClone, new Date() )
    let dayIsBetween

    if( isBefore(start, end) ){
      dayIsBetween = isWithinInterval(dateClone, { start, end })
    }else{
      dayIsBetween = false
    }

    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween || isFirstDay,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
      [classes.nonCurrentMonthDay]: disablePastDay,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  render() {
    const { selectedDate, handleDateChange } = this.state;
    //console.log(selectedDate)
    const  [start , end ] = selectedDate

    return (
      <DatePicker
        label="Week picker"
        variant="static"
        disableToolbar
        autoOk
        value={start}
        onChange={handleDateChange}
        renderDay={this.renderWrappedWeekDay}
        labelFunc={this.formatWeekSelectLabel}
      />
    );
  }
}

const styles = createStyles(theme => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default withStyles(styles)(DateRangePicker);