import React from "react"
import { Container, Typography, Grid, makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import "./footer.css"
import { Capsul } from "./Capsul/Capsul"
import ig from "./instagram.png"
import capsul_logo from "./capsul-logo-white-sm.png"

const Footer = (props) => {
  const classes = useStyle()
  const capsul_url = process.env.REACT_APP_CAPSUL_URL

  return (
    <Container className={classes.container} maxWidth="xl">
      <footer className={classes.footer}>
        <Grid container>
          <Grid container className={"box1"} item md={12}>
            <Grid item md={3}>
              <img src={capsul_logo} alt="logo" width="130" />
              <a
                href="https://www.instagram.com/"
                target="_blank"
                width="130"
                style={{ marginLeft: 15 }}
              >
                <img src={ig} alt="instagram" />
              </a>
            </Grid>
            <Grid container item md={9}>
              <Grid item xs={12} md={3}>
                <Typography variant="h4" align="center">
                  <a
                    href={capsul_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="textSecondary"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Découvrir Capsul Events
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="h4" align="center">
                  <Link
                    to="/terms"
                    color="textSecondary"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Mentions légales
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography variant="h4" align="center">
                  <Link
                    to="/contact"
                    color="textSecondary"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Contact
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography variant="h4" align="center" color="textSecondary">
                  <Link
                    to="/contact-sav"
                    color="textSecondary"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    SAV
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography variant="h4" align="center" color="textSecondary">
                  <Link
                    to="/contact-reclamation"
                    color="textSecondary"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Réclamation
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Capsul />
        </Grid>
      </footer>
    </Container>
  )
}

Footer.propTypes = {
  logo: PropTypes.string.isRequired,
}

export default Footer

const useStyle = makeStyles({
  footer: {
    marginLeft: "100px",
    marginRight: "100px",
    paddingTop: 50,
    paddingBottom: 50,
    "& img": {
      width: 150,
    },
    "& .box1": {
      marginBottom: 50,
      "& .MuiGrid-item": {
        paddingLeft: 45,
        paddingRight: 45,
      },
    },
  },
  padding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  container: {
    height: "100%",
    padding: 0,
    backgroundColor: "#333333",
    "& .MuiGrid-grid-xs-4": {
      marginLeft: 100,
    },
  },
})
