const config = {
  store: {
    opencartUrl: process.env.REACT_APP_OC_URL,
    id: process.env.REACT_APP_STORE_ID,
    customer_group_id: process.env.REACT_APP_CUSTOMER_GROUP_ID,
    ocAuth: {
      username: process.env.REACT_APP_OC_AUTH_USER,
      password: process.env.REACT_APP_OC_AUTH_PASSWORD,
    },
    attribute: {
      name: process.env.REACT_APP_ATTRIBUTE,
    },
    product: {
      options: {
        baggage: {
          name: process.env.REACT_APP_BAGGAGE,
        },
        loyalty: {
          name: process.env.REACT_APP_LOYALTY,
        },
        special: {
          name: process.env.REACT_APP_SPECIAL,
        },
        insurance: {
          name: process.env.REACT_APP_INSURANCE,
        },
        transfer: {
          name: process.env.REACT_APP_TRANSFER,
        },
        additionalProducts: {
          name: process.env.REACT_APP_ADDITIONAL_PRODUCTS,
        },
      },
    },
    payment: {},
  },
}

if (process.env.REACT_APP_PAYPAL_CLIENT_ID) {
  config.store.payment["paypal"] = {
    client_id: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  }
}

export default config
