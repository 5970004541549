import React from "react"
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core"

const useStyle = makeStyles({
  containerDialog: {
    "& .MuiDialog-paper": {
      maxWidth: 371,
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      "& .MuiButton-root": {
        width: "70%",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  containerDatePicker: {
    overflow: "hidden",
  },
})

export const Flight = ({ flights, airline, handleInputChange }) => {
  const classes = useStyle()

  return (
    <>
      {flights?.length >= 1 && (
        <Grid container item md={12} style={{ padding: 8 }}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="airline">
              Aéroport de départ
            </InputLabel>
            <Select
              value={airline}
              name="airline"
              labelId="airline"
              onChange={handleInputChange}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                Sélectionner Aéroport de départ
              </MenuItem>
              {flights.map((flight, index) => (
                <MenuItem key={flight.departure_airport} value={index}>
                  {flight.departure_airport} ( +{flight.price_formated} )
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </>
  )
}
