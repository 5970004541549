class LocalStorageCache {
  get(key) {
    return JSON.parse(window.localStorage.getItem(key))?.data
  }

  set(key, data) {
    window.localStorage.setItem(
      key,
      JSON.stringify({ data: data, timestamp: new Date().getTime() })
    )
  }

  reset() {
    localStorage.removeItem("cart")
    localStorage.removeItem("formValues")
    //window.localStorage.clear()
  }
}

export default new LocalStorageCache()
