import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import { priceFormat } from "./../../../Helpers/store"
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ConfirmBaggageDialog({
  service,
  option,
  open,
  setOpen,
  setSelected,
  value,
  addBaggage,
  deleteBagage,
}) {
  const [quantity, setQuantity] = useState(1)

  const { name, price } = option

  const handleClose = () => {
    setSelected(null)
    setOpen(false)
  }

  const renderSelect = () => {
    let menuitem = []
    for (let i = 1; i <= 5; i++) {
      menuitem.push(
        <MenuItem value={i}>
          <em>{i}</em>
        </MenuItem>
      )
    }
    return menuitem
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{`Voulez-vous ajouter un ${service} en plus ?`}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            color="textPrimary"
          >
            <Grid md={12} xs={12}>
              {name} - {priceFormat(price)}
            </Grid>
            <Grid container justify="center" md={12} xs={12}>
              <FormControl>
                <InputLabel shrink id="quantity">
                  Quantité
                </InputLabel>
                <Select
                  value={quantity}
                  name="quantity"
                  labelId="quantity"
                  onChange={(e) => {
                    console.log(e.target.value)
                    setQuantity(e.target.value)
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {renderSelect()}
                </Select>
              </FormControl>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          {value[option.option_value_id] ? (
            <Button
              onClick={() => deleteBagage(option, quantity)}
              color="primary"
            >
              Supprimer
            </Button>
          ) : (
            <Button
              onClick={() => addBaggage(option, quantity)}
              color="primary"
            >
              Valider
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
