import { Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { useFetch } from "../../../hooks/useFetch"
import ajax from "../../../Helpers/ajax"
import { Loading } from "../../Loading/Loading"
import { Link } from "react-router-dom"
import { dateFormat } from "../../../Helpers/dateFormat"

const useStyle = makeStyles({
  box2: {
    paddingLeft: 50,
    paddingRight: 50,
    "& .MuiGrid-container": {
      marginTop: 20,
      "& .MuiGrid-item": {
        marginTop: 20,
      },
    },
  },
})
const AddressList = ({ address }) => {
  const { loading, data, error } = useFetch(ajax.getCapsuls(address.capsul_id))

  const { data: addresses } = data || {}

  const date_start =
    address.date_start !== ""
      ? dateFormat(address.date_start, "MM/DD/YYYY")
      : ""

  return (
    <Grid item xs={12} md={3}>
      {address.link !== "" ? (
        <Typography variant="h4" align="center" color="textSecondary">
          <a
            href={address.link}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {address.title}{" "}
          </a>
        </Typography>
      ) : (
        <Typography variant="h4" align="center" color="textSecondary">
          {address.title}
        </Typography>
      )}

      {!loading && data ? (
        <>
          {addresses.map((address, index) => {
            return (
              <React.Fragment key={index}>
                <Typography variant="h5" align="center" color="textSecondary">
                  {address.title} {address.address}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                >
                  {date_start}
                </Typography>
              </React.Fragment>
            )
          })}
        </>
      ) : (
        <Loading isHome={false} />
      )}
    </Grid>
  )
}

export const Capsul = () => {
  const classes = useStyle()

  const { loading, data, error } = useFetch(ajax.getCapsuls())

  const { data: addressList } = data || {}

  return !loading && data ? (
    <Grid container className={classes.box2} item md={12}>
      <Grid item md={12}>
        <Typography variant="body2" align="left" color="textSecondary">
          RETROUVEZ LA CAPSUL EN TOURNÉE
        </Typography>
        <hr style={{ opacity: 0.2 }} />
      </Grid>
      <Grid container item md={12}>
        {addressList.map((address) => (
          <AddressList key={address.capsul_id} address={address} />
        ))}
      </Grid>
    </Grid>
  ) : (
    <Loading isHome={false} />
  )
}
