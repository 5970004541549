import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"
import React, { useEffect } from "react"
import ajax from "../../Helpers/ajax"
import { useFetch } from "../../hooks/useFetch"

export const CountryField = ({ country, handleChange, errors }) => {
  const { data, loading } = useFetch(ajax.getCountry())

  const { data: countries, error, success } = data || {}

  return (
    <>
      <FormControl style={{ width: "100%" }} error={errors?.error}>
        <InputLabel id="country-label">Pays</InputLabel>
        <Select
          labelId="country-label"
          id="label"
          value={country}
          name="country"
          onChange={handleChange}
        >
          {!loading &&
            countries.map(
              (country) =>
                country.status && (
                  <MenuItem key={country.country_id} value={country.country_id}>
                    {country.name}
                  </MenuItem>
                )
            )}
        </Select>
        <FormHelperText>{errors?.helperText}</FormHelperText>
      </FormControl>
    </>
  )
}
