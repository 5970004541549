import React, { useState, useEffect } from "react"
import { Grid, Typography, makeStyles } from "@material-ui/core"
import FlagMenu from "./FlagMenu"

const useStyle = makeStyles({
  root: {
    paddingRight: 10,
    "& Button": {
      borderRadius: 7,
      padding: 5,
      minWidth: 59,
    },
  },
})

const COUNTRYFLAGS = "https://www.countryflags.io/"
const COUNTRY = "https://flagpedia.net/data/flags/w580/"

export const Nationality = ({
  label,
  value,
  handleInputWithOnChange,
  pos,
  handleInputChangePassenger,
}) => {
  const classes = useStyle()

  const [nationality, setNationality] = useState(value)

  useEffect(() => {
    handleInputChangePassenger({ name: label, value: nationality }, pos)
  }, [nationality])

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid item md={12} xs={12}>
        <Typography variant="caption">Nationalité</Typography>
      </Grid>
      <Grid item md={6}>
        {nationality && (
          <img
            style={{ width: 48 }}
            src={`${COUNTRY}${nationality.toLowerCase()}.png`}
            alt="flag"
          />
        )}
        <input type="hidden" name="nationality" value={nationality} />
      </Grid>
      <Grid item md={6}>
        <FlagMenu
          nationality={nationality}
          selectedNationality={setNationality}
        />
      </Grid>
    </Grid>
  )
}
