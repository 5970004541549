import React from 'react'
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core'
import {language as t}  from './../language'
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import logoRed from './logo-red.png'

const useStyle =  makeStyles({
    pTop:{
        paddingTop:30
    },
    pBottom:{
        paddingBottom:30
    },
    ul:{
        listStyle: 'none',
    }
})

const InfoAddress = React.memo(( props ) => {
    const classes = useStyle()
    const { config_info_open_start:open_start, 
            config_info_open_end:open_end,
            config_info_address_1:address_1,
            config_info_address_2:address_2,
            config_info_city:city,
            config_info_zipcode:zipcode,
            config_info_metro_1:metro_1,
            config_info_metro_2:metro_2,
            store_geocode,
        } = props.store

        const md = (store_geocode) ? {md:4} : {md:12}
    return (
        <>
            <Typography variant="h4">
                {t.title_section_3} <Typography variant="h5" component="span" color="secondary"> (du {open_start} au {open_end})</Typography>
            </Typography>
            <Grid container className={`${classes.pTop} ${classes.pBottom}`} alignItems="center" >
                <Grid item {...md}>
                    <Grid item xs={10}>
                        <img src={logoRed}  width="200px" alt=""/>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h5">
                            {address_1} {address_2} {zipcode} {city}
                        </Typography>
                        <ul className={classes.ul}>
                            <Typography variant="body1" component="li">
                                <DirectionsTransitIcon style={{top: '7px',position: 'relative',}} color="secondary"/> 
                                {metro_1}
                            </Typography>
                            <Typography variant="body1" component="li">
                                <DirectionsTransitIcon style={{top: '7px',position: 'relative',}} color="secondary"/> 
                                {metro_2}
                            </Typography>
                        </ul>
                    </Grid>
                </Grid>
                {store_geocode &&
                <Grid item md={8}> 
                    <iframe title="mapa" src={store_geocode} width="100%" height="250px" frameBorder="0"  allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </Grid>
                }
            </Grid>
        </>
    )
})

InfoAddress.propTypes = {
    store: PropTypes.object.isRequired,
};

export default InfoAddress