import React from "react"
import { Link } from "react-router-dom"
import { Container, Typography, Grid, Box, makeStyles } from "@material-ui/core"
import { language as t } from "./../../language"
import "./style.css"
import "./header.png"
import capsul_logo from "./capsul-logo-white-sm.png"
import PropTypes from "prop-types"

const useStyle = makeStyles({
  root: {
    color: "white",
  },
  padding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  banner: {
    background: "white",
    height: 80,
  },
  container: {
    height: "100%",
    "& .MuiGrid-grid-xs-4": {
      marginLeft: 0,
      bottom: 40,
      position: "relative",
    },
    "& img": {
      width: 200,
    },
  },
})

const Header = (props) => {
  const classes = useStyle(props)
  const { title, store_image, logo, isCheckOutPage } = props
  return (
    <Container className={classes.padding} maxWidth="xl">
      <Link
        to="/"
        color="textSecondary"
        style={{ color: "white", textDecoration: "none" }}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.banner}
          alignContent="center"
        >
          <img src={logo} width="70px" height="70px" alt="" />
        </Grid>
        <header style={{ backgroundImage: "url(" + store_image + ")" }}>
          <Container maxWidth="lg" style={{ height: "100%" }}>
            <Grid
              className={classes.container}
              container
              justify="flex-start"
              alignContent="flex-end"
            >
              <Grid item xs={4}>
                <Box>
                  {isCheckOutPage && (
                    <Typography
                      className={classes.root}
                      variant="h1"
                      color="textSecondary"
                    >
                      {title}
                    </Typography>
                  )}

                  <Typography
                    className={classes.root}
                    variant="body2"
                    color="textSecondary"
                  >
                    {t.immersion_par}
                  </Typography>
                  <img src={capsul_logo} alt="" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </header>
      </Link>
    </Container>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  store_image: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
}

export default Header
