import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  makeStyles,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@material-ui/core/"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"

// pick a date util library
import MomentUtils from "@date-io/moment"
import moment from "moment"
import "moment/locale/fr"
import DateRangePicker from "./DateRangePicker"
import { useState } from "react"
import { useForm } from "../../hooks/useForm"
import CartContext from "../../Context/CartContext"
import { useHistory } from "react-router-dom"
import { Flight } from "./Flight/Flight"
import { priceFormat } from "../../Helpers/store"
import { getDaysPerNight } from "../../Helpers/daysPerNight"
import { isAfter } from "date-fns/esm"
import { StoreContext } from "../../Context/StoreContext"
import { makeJSDateObject } from "../../Helpers/dateFormat"
import localStorageCache from "../../Helpers/localStorageCache"
import { saveSession } from "../../Helpers/session"
moment.locale("fr")

const useStyle = makeStyles({
  containerDialog: {
    "& .MuiDialog-paper": {
      maxWidth: 371,
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      "& .MuiButton-root": {
        width: "70%",
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  containerDatePicker: {
    overflow: "hidden",
  },
  price: {
    fontFamily: "modulus-pro-bold",
    width: "100%",
  },
  sale: {
    fontFamily: "modulus-pro-bold",
    display: "none",
    width: "100%",
    textDecoration: "line-through",
    fontSize: 15,
  },
})

function CartDialog({ open, handleClose, product, category, fields }) {
  const classes = useStyle()

  const { cart, email } = useContext(CartContext)
  const { store_name } = useContext(StoreContext)

  const { formValues, handleInputChange, handleInputDate, reset } = useForm(
    fields
  )
  const [includeVol, setIncludeVol] = useState(false)
  const {
    product_id,
    name,
    sku,
    price,
    price_formated,
    flights,
    special,
    special_start_date,
    special_end_date,
    special_formated,
  } = product

  useEffect(() => {
    setIncludeVol(false)
    reset()
  }, [product])

  let history = useHistory()

  const { airline, vol, quantity, start, end } = formValues

  let currentPrice = 0
  let discountPrice = 0
  if (price) {
    currentPrice = priceFormat(price, "€")
    discountPrice = priceFormat(special, "€")
  }

  let click = true
  /*const handleDateChange = (date) => {
    if (click) {
      //start
      handleInputDate({ name: "arrived", value: makeJSDateObject(date) })
      //setSelectedDate(d => [ d[0], makeJSDateObject(date) ]);
      click = false
    } else {
      //end date
      //setSelectedDate(d => [ makeJSDateObject(date), d[1]]);
      handleInputDate({ name: "departed", value: makeJSDateObject(date) })
      click = true
    }
    //setSelectedDate(makeJSDateObject(date));
  }*/

  const handleSubmit = () => {
    if (isAfter(start, end)) {
      alert("the arrival date cannot be greater than the departure date")
      return null
    }

    let daysPerNight = 1
    if (end) {
      daysPerNight = getDaysPerNight(start, end)
    }

    const opt = includeVol ? flights[airline] : undefined

    const item = {
      product_id,
      name,
      sku,
      category,
      price,
      price_formated,
      special,
      special_start_date,
      special_end_date,
      special_formated,
      daysPerNight,
      quantity,
      options: {
        ...formValues,
        extends_airline: opt,
        price: opt?.price ? opt?.price : 0,
      },
    }
    const product = cart.getFirstItem()
    //Re-write the cart
    if (product) {
      if (
        product.product_id == item.product_id &&
        product.quantity == quantity
      ) {
        cart.reset()
      } else {
        cart.reset()
        localStorageCache.reset()
      }
    }

    cart.addItem(item)
    localStorageCache.set("cart", cart)

    //Save Session
    /*if (email !== "") {
      const data = {
        email,
        data: { cart: cart },
      }
      saveSession(email, { cart, formValues })
    }*/

    history.push("/checkout/step-1")
  }

  const handleDateStartChange = (date) => {
    handleInputDate({ name: "start", value: makeJSDateObject(date) })
    click = false
  }
  const handleDateEndChange = (date) => {
    handleInputDate({ name: "end", value: makeJSDateObject(date) })
    click = true
  }

  let propsDate = {}
  if (special_start_date !== "") {
    propsDate.minDate = new Date(special_start_date)
  }
  if (special_end_date !== "") {
    propsDate.maxDate = new Date(special_end_date)
  }

  const col = formValues?.end ? 6 : 12

  const startDateLabel =
    category === "event" ? "Sélectionnez une date" : "Date d’arrrivée"

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        scroll="body"
        maxWidth="xs"
        className={classes.containerDialog}
      >
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography
            variant="body1"
            style={{ fontSize: "18px" }}
            align="center"
          >
            {store_name}
          </Typography>
          <Typography variant="h3" align="center">
            {name}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale="fr"
          >
            <Grid container justify="center">
              <Grid item xs={12} className={classes.containerDatePicker}>
                <DateRangePicker
                  selectedDate={[start, end]}
                  handleDateChange={() => {}}
                  days={2}
                />
              </Grid>

              <Grid container item xs={12} spacing={2}>
                {formValues?.start && (
                  <Grid item xs={col}>
                    <KeyboardDatePicker
                      {...propsDate}
                      autoOk
                      minDate={new Date()}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      margin="normal"
                      id="arrived"
                      name="start"
                      label={startDateLabel}
                      value={formValues.start}
                      onChange={handleDateStartChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                )}

                {formValues?.end && (
                  <Grid item xs={col}>
                    <KeyboardDatePicker
                      {...propsDate}
                      autoOk
                      minDate={start}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      margin="normal"
                      id="departed"
                      name="end"
                      minDateMessage={
                        "La date ne doit pas être antérieure à la date d’arrivée"
                      }
                      label="Date de Départ"
                      value={formValues.end}
                      onChange={handleDateEndChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid container item md={12} spacing={2}>
                <Grid container item md={col}>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink id="quantity">
                      Nombre
                    </InputLabel>
                    <Select
                      value={formValues.quantity}
                      name="quantity"
                      labelId="quantity"
                      onChange={handleInputChange}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={1}>
                        <em>1 Personne</em>
                      </MenuItem>
                      <MenuItem value={2}>2 Personnes</MenuItem>
                      <MenuItem value={3}>3 Personnes</MenuItem>
                      <MenuItem value={4}>4 Personnes</MenuItem>
                      <MenuItem value={5}>5 Personnes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {flights?.length > 0 && (
                  <Grid container item md={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink id="vol">
                        VOL INCLUS ?
                      </InputLabel>
                      <Select
                        value={vol}
                        name="vol"
                        labelId="vol"
                        onChange={handleInputChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem
                          value={0}
                          onClick={() => setIncludeVol(false)}
                        >
                          Non
                        </MenuItem>
                        <MenuItem value={1} onClick={() => setIncludeVol(true)}>
                          Oui
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              {includeVol && (
                <Flight
                  flights={flights}
                  airline={airline}
                  handleInputChange={handleInputChange}
                />
              )}
              <Grid container item md={12}>
                <Typography
                  variant="h2"
                  className={special !== 0 ? classes.sale : classes.price}
                  component="p"
                  align="center"
                  color="secondary"
                >
                  {currentPrice}
                </Typography>
                {special !== 0 && (
                  <Typography
                    variant="h2"
                    className={classes.price}
                    component="p"
                    align="center"
                    color="secondary"
                  >
                    {discountPrice}
                  </Typography>
                )}
                <Typography
                  variant="h5"
                  className={classes.price}
                  component="p"
                  align="center"
                  color="secondary"
                >
                  par jour / par personne
                </Typography>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

CartDialog.propTypes = {}

export default CartDialog
