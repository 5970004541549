import React, { useContext, useState } from "react"
import { Typography, Grid, Paper, makeStyles, Icon } from "@material-ui/core"
import StarIcon from "@material-ui/icons/Star"
import CartContext from "../../Context/CartContext"
import { searchProduct, getOption } from "../../Helpers/products"
import { ErrorMessage } from "../ErrorMessage/ErrorMessage"
import config from "../../Config"
import ProductsContext from "../../Context/ProductsContext"

const useStyle = makeStyles({
  paperContainer: {
    cursor: "pointer",
    border: "1px solid transparent",
  },
})

//const fidelities = [ 'SKYMILES', 'SKYWARDS', 'ADVANTAGE', 'MILE & MORE', 'MILES & SOURIRES', 'LIFEMILES', 'BLEU VOLANT', 'CLUB EXECUTIF' ]

export const Loyalty = ({ value, pos, handleInputChangePassenger, error }) => {
  const classes = useStyle()

  const { cart } = useContext(CartContext)
  const products = useContext(ProductsContext)

  let item = cart.getFirstItem()

  const nameOpt = config.store.product.options.loyalty.name

  const product = searchProduct(products, item?.product_id)

  const options = getOption(product, nameOpt)

  const { option_value: fidelities } = options ? options : {}

  //const [value, setValue] = useState(name)

  const handleClick = (e, fidelity) => {
    const target = e.currentTarget
    if (target.classList.contains("active")) {
      target.classList.remove("active")
      handleInputChangePassenger({ name: "loyalty_program", value: {} }, pos)
      return
    }
    handleInputChangePassenger(
      { name: "loyalty_program", value: fidelity },
      pos
    )
    document
      .querySelectorAll(".fidelity")
      .forEach((el) => el.classList.remove("active"))
    target.classList.add("active")
  }
  return (
    <>
      {fidelities && (
        <>
          <Grid item md={12}>
            <Typography variant="h4" style={{ paddingTop: 20 }}>
              Programmes de fidélité
            </Typography>
          </Grid>
          <Grid container item md={12} xs={12} spacing={3}>
            {fidelities.map((fidelity, index) => {
              return (
                <Grid item md={4} xs={6} key={fidelity.option_value_id}>
                  <Paper
                    elevation={1}
                    onClick={(e) => handleClick(e, fidelity)}
                    className={`${classes.paperContainer} fidelity`}
                  >
                    <Icon style={{ fontSize: 30 }}>{fidelity.icon}</Icon>
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ fontSize: "10pt" }}
                    >
                      {fidelity.name}
                    </Typography>
                  </Paper>
                </Grid>
              )
            })}
          </Grid>
          <ErrorMessage error={error} />
        </>
      )}
    </>
  )
}
