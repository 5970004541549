import React from "react"
import PropTypes from "prop-types"
import { Grid } from "@material-ui/core"
// pick a date util library
import MomentUtils from "@date-io/moment"
import moment from "moment"
import "moment/locale/fr"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import { isBefore } from "date-fns"
import {
  dateFormatOfTodayWithEighteenLessYears,
  makeJSDateObject,
} from "../../Helpers/dateFormat"

moment.locale("fr")

const DatePickerInput = ({
  label,
  name,
  value,
  handleInputDate,
  pos,
  handleInputChangePassenger,
  isValidated = false,
}) => {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(value)

  const handleDateChange = (date) => {
    /*handleInputDate({
            name,
            value: date
        })*/
    if (pos == 0) {
      isOlderAge(date)
    }

    handleInputChangePassenger(
      {
        name,
        value: date,
      },
      pos
    )

    setSelectedDate(date)
  }

  const isOlderAge = (date) => {
    const dateSelected = makeJSDateObject(date)
    const today = dateFormatOfTodayWithEighteenLessYears()
    if (isBefore(today, dateSelected)) {
      return false
    } else {
      return true
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          id="date-picker-inline"
          label={label}
          value={selectedDate}
          invalidDateMessage={"Format de date non valide"}
          maxDateMessage={"Ce passager doit être majeur"}
          onChange={handleDateChange}
          maxDate={
            isValidated
              ? dateFormatOfTodayWithEighteenLessYears()
              : new Date(2100, 1, 1)
          }
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

DatePickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  handleInputDate: PropTypes.func.isRequired,
}

export default DatePickerInput
