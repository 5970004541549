import React from "react"
import { Grid, CardMedia, makeStyles } from "@material-ui/core"

const useStyle = makeStyles({
  media: {
    height: 74,
    width: "100%",
    borderRadius: 10,
    boxShadow: "2px 2px 1px 0px rgba(0,0,0,0.1)",
  },
})

function GridImages({ gallery }) {
  const images = gallery ? gallery : []

  const classes = useStyle()

  const renderImg = (images) => {
    return images.map((img, index) => {
      if (img !== "") {
        return (
          <Grid item xs={4} md={2} key={index} style={{ padding: 2 }}>
            <CardMedia className={classes.media} image={img}></CardMedia>
          </Grid>
        )
      }
    })
  }

  return (
    <Grid container item xs={12}>
      {renderImg(images)}
    </Grid>
  )
}

export default GridImages
