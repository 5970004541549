import ajax from "./ajax"
import localStorageCache from "./localStorageCache"

import { differenceInDays, isAfter } from "date-fns/esm"
import Cart from "../Classes/Cart"

export const getSession = (email) => {
  const { url, initial } = ajax.getSession(email)
  return fetchData(url, initial)
}

export const saveSession = (email, { formValues, cart }) => {
  localStorageCache.set("formValues", formValues)
  localStorageCache.set("cart", cart)
  if (email !== "") {
    const data = {
      email,
      data: { cart: cart, formValues: formValues },
    }
    const { url, initial } = ajax.sendSession(JSON.stringify(data))
    fetchData(url, initial)
  }
}

const fetchData = async (url, initial) => {
  const response = await fetch(url, initial)
  const json = await response.json()
  let data = JSON.parse(json.data.data)
  const prev_product = data.cart.items[0]
  data = verifyCart(data)
  return {
    ...json,
    data: { ...data },
    prev_product,
  }
}

const verifyCart = (data) => {
  let { cart, formValues } = data
  const item = cart.items[0]
  const start = Date.parse(item.options.start)
  if (!isDayValidToToday(start)) {
    Cart.reset()
    formValues.option_vol = { additional_conditionals: false }
    cart = Cart
  }
  return data
}

const isDayValidToToday = (start) => {
  const MAX_DAY = 1
  const today = new Date()
  if (isAfter(start, today)) {
    if (differenceInDays(start, today) >= MAX_DAY) {
      return true
    }
  }

  return false
}
