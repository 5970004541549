import React from "react"
import { createContext } from "react"
import { useFetch } from "../hooks/useFetch"
import ajax from "../Helpers/ajax"
import { Loading } from "../Components/Loading/Loading"

const ProductsContext = createContext([])

export function ProductsContextProvider({ children }) {
  const { data, loading } = useFetch(ajax.getProducts())
  const products = data?.data

  return (
    <>
      {loading && <Loading isHome={true} />}
      {!loading && (
        <ProductsContext.Provider value={products}>
          {children}
        </ProductsContext.Provider>
      )}
    </>
  )
}

export default ProductsContext
