import { subYears } from "date-fns"
import moment from "moment"
moment.locale("fr")

export const dateFormat = (date, format = "ll") => {
  return moment(date).format(format)
}

export const makeJSDateObject = (date) => {
  return moment(date).clone().toDate()
}

export const dateFormatOfTodayWithEighteenLessYears = () => {
  return subYears(new Date(), 18)
}
