import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Grid,
  CardMedia,
  Button,
  Typography,
  makeStyles,
  Paper,
} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import PropTypes from "prop-types"
import { priceFormat } from "../../Helpers/store"

import { useHistory } from "react-router-dom"

const useStyle = makeStyles((theme) => ({
  paperContainer: {
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 20,
  },
  gridContainer: {
    padding: 10,
  },
  title: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  cardMedia: {
    borderRadius: 10,
    height: 259,
    width: "100%", //194
  },
  attributes: {
    padding: 0,
    listStyle: "none",
    "& .MuiSvgIcon-root": {
      top: "7px",
      position: "relative",
    },
  },
  price: {
    fontFamily: "modulus-pro-bold",
  },
  sale: {
    textDecoration: "line-through",
    fontSize: 15,
  },
  a: {
    textDecoration: "none",
  },
}))

const ProductCard = (props) => {
  const history = useHistory()

  const classes = useStyle()
  const { product, handleOpen } = props
  const {
    product_id,
    id,
    name,
    original_image: image,
    price,
    price_formated,
    special,
    special_formated,
    special_start_date,
    special_end_date,
    attribute_groups,
    description,
    meta_description,
  } = product

  useEffect(() => {
    if (history.location.state?.product.product_id === product_id) {
      handleOpen()
    }
  }, [])

  const [attributes] = attribute_groups?.filter(
    (attribute) => attribute.name === "advantage"
  )

  const renderAttributes = () => {
    return attributes?.attribute.map((attribute) => (
      <li key={attribute.attribute_id + attribute.text}>
        <CheckIcon color="secondary"></CheckIcon> {attribute.text}
      </li>
    ))
  }

  return (
    <Paper className={classes.paperContainer} elevation={2}>
      <Grid
        container
        item={true}
        className={classes.gridContainer}
        md={12}
        spacing={3}
      >
        <Grid item xs={3}>
          <Link to={`/product/${id}`} style={{ textDecoration: "none" }}>
            <CardMedia
              className={classes.cardMedia}
              image={image}
              component="img"
            />
          </Link>
        </Grid>
        <Grid container item xs={9} alignItems="center">
          <Grid item xs={12}>
            <Link
              to={`/product/${id}`}
              style={{ textDecoration: "none" }}
              color="secondary"
            >
              <Typography variant="h4" className={classes.title}>
                {name}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link to={`/product/${id}`} style={{ textDecoration: "none" }}>
              <Typography
                color="textPrimary"
                variant="body1"
                dangerouslySetInnerHTML={{ __html: meta_description }}
              ></Typography>
            </Link>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" component="div">
                <ul className={classes.attributes}>{renderAttributes()}</ul>
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              justify="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid
                container
                item
                xs={12}
                align="right"
                style={{ display: "contents" }}
              >
                <Grid container item md={6} direction="column">
                  <Typography
                    variant="h4"
                    color="secondary"
                    display="inline"
                    className={special !== 0 ? classes.sale : classes.price}
                  >
                    {priceFormat(price)}
                  </Typography>
                  {special !== 0 && (
                    <Typography
                      variant="h4"
                      wrap="nowrap"
                      color="secondary"
                      display="inline"
                      className={classes.price}
                    >
                      {priceFormat(special)}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    color="secondary"
                    disableElevation
                  >
                    Réserver
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
}

export default React.memo(ProductCard)
