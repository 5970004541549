import React from "react"
import { Container, Grid, makeStyles, Typography } from "@material-ui/core"
import ajax from "../../Helpers/ajax"
import { useFetch } from "../../hooks/useFetch"
import Header from "../Header"
import Footer from "../Footer"
import _ from "lodash"
import { Loading } from "../Loading/Loading"
import { ContentNotFound } from "../ContentNotFound/ContentNotFound"

const useStyle = makeStyles({
  root: {
    padding: 30,
  },
  gridContainer: {
    marginTop: 15,
    marginBottom: 15,
  },
  pTop: {
    paddingTop: 30,
  },
  pBottom: {
    paddingBottom: 30,
  },
})

export const ThankYouPage = ({ store, slug }) => {
  const classes = useStyle()
  const { store_name, store_image, logo } = store
  const infoPage = useFetch(ajax.getInformationPageByMetaTitle(slug))

  const { loading, data: info } = infoPage
  const { data, error, success } = info || {}

  console.log(error)
  return (
    <>
      <Header title={store_name} store_image={store_image} logo={logo} />
      <Container className={classes.root} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {success ? (
                !loading && data ? (
                  <Grid container className={classes.contentText} item xs={12}>
                    <Grid item md={12}>
                      <Typography variant="h3">{data.title}</Typography>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      className={`${classes.pTop} ${classes.pBottom}`}
                    >
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: _.unescape(data.description),
                        }}
                      ></Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Loading isHome={false} />
                )
              ) : (
                <ContentNotFound />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer logo={logo} />
    </>
  )
}
