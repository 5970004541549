import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import ReactDOM from "react-dom"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import ajax from "./Helpers/ajax"
import { SnackbarProvider } from "notistack"
import { StoreProvider } from "./Context/StoreContext"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff4545",
    },

    secondary: {
      main: "#ff4545",
    },
    text: {
      secondary: "white",
    },
  },
  typography: {
    fontFamily: "modulus-pro-regular",
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: "modulus-pro-semi-bold",
        fontSize: "51px",
      },
      h2: {
        fontFamily: "modulus-pro-semi-bold",
        fontSize: "40px",
      },
      h3: {
        fontFamily: "modulus-pro-semi-bold",
        fontSize: "33px",
      },
      h4: {
        fontFamily: "modulus-pro-semi-bold",
        fontSize: "24px",
      },
      h5: {
        fontFamily: "modulus-pro-semi-bold",
        fontSize: "16px",
      },
      h6: {
        fontFamily: "modulus-pro-semi-bold",
        fontSize: "13px",
      },
      body1: {
        fontSize: "16px",
        //marginTop:33
      },
      body2: {
        fontSize: "16px",
      },
      caption: {
        fontSize: "13px",
      },
    },
    MuiButton: {
      root: {
        fontFamily: "modulus-pro-semi-bold",
        borderRadius: "30px",
        marginTop: 10,
        marginBottom: 5,
      },
      text: {
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 20,
      },
    },
    MuiFormLabel: {
      root: {
        color: "black",
      },
    },
    MuiTextField: {
      fontSize: "16pt",
    },
    MuiRadio: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    MuiCheckbox: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    MuiTab: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
      },
      textColorSecondary: {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
  },
})

const reactDOM = () =>
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <StoreProvider>
            <App />
          </StoreProvider>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>,
    document.getElementById("root")
  )

if (ajax.getLocalStorageExist()) {
  reactDOM()
} else {
  ajax.getToken().then((data) => {
    reactDOM()
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
