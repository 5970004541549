import { useState, useRef, useEffect } from "react"

export const useFetch = ({ url, initial }) => {
  const isMounted = useRef(true)
  const [state, setState] = useState({
    data: null,
    loading: true,
    error: null,
  })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url, initial)
      if (response.status === 401) {
        localStorage.removeItem("oc_token")
        window.location.reload()
      }
      const data = await response.json()
      if (isMounted.current) {
        setState({
          loading: false,
          error: null,
          data,
        })
      } else {
        console.log("setState is not called")
      }
    }

    fetchData()
    return () => {
      isMounted.current = false
    }
  }, [])

  return state
}
