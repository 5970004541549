import React from 'react'
import { Chip, Grid, Typography, makeStyles  } from '@material-ui/core'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyle = makeStyles({
    muiChip:{
        margin:'10px 0',
        boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        backgroundColor:'white',
        '& .MuiChip-label':{
            fontFamily:'modulus-pro-semi-bold'
        }
    }
})

export const FlightDetails = ( { airline } ) => {
    const classes =  useStyle()

    const { airline:name, departure_airport, arrival_airport, } = airline
    return (
        <>
            <Chip className={classes.muiChip} size="small" label={name}/>
            <Grid container item alignItems="center">
                <Grid item md={3} >
                    <Typography variant="h5" align="center">
                    {departure_airport}
                    </Typography>
                    {/* <Typography variant="caption" align="center">
                    CDT
                    </Typography> */}
                </Grid>
                <Grid item md={3} >
                    <Typography variant="h5" align="center">
                        <ArrowForwardIcon/>
                    </Typography>
                </Grid>
                <Grid item md={3} >
                    <Typography variant="h5" align="center">
                    {arrival_airport}
                    </Typography>
                    {/* <Typography variant="caption" align="center">
                    XDT
                    </Typography> */}
                </Grid>
            </Grid> 
        </>
    )
}
