import React, { useState } from "react"
import PropTypes from "prop-types"
import { Grid, Typography, makeStyles } from "@material-ui/core"
import ProductCard from "../ProductCard"
import CartDialog from "../CartDialog"
import { getCategory } from "../../Helpers/products"
import { generateFormForCart } from "../../Helpers/validation"

const useStyle = makeStyles({
  gridContainer: {
    marginTop: 15,
    marginBottom: 15,
  },
})

function GridProductsCard({ products }) {
  const classes = useStyle()
  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  const handleOpen = (product) => {
    setSelectedProduct((p) => product)
    setOpen((o) => true)
  }
  const handleClose = () => {
    setSelectedProduct(null)
    setOpen((o) => false)
  }

  const renderProductsCard = () =>
    products.map((product) => (
      <Grid item xs={12} key={product.id}>
        <ProductCard product={product} handleOpen={() => handleOpen(product)} />
      </Grid>
    ))

  const renderCartDialog = () => {
    if (!selectedProduct) {
      return
    }
    const [category] = selectedProduct ? getCategory(selectedProduct) : []

    const cartForm = generateFormForCart(category?.name.toLowerCase())

    if (cartForm?.fields) {
      const { name, fields } = cartForm
      return (
        <CartDialog
          open={open}
          handleClose={handleClose}
          product={selectedProduct}
          category={name}
          fields={fields}
        />
      )
    }
  }

  return (
    <Grid container className={`${classes.gridContainer} boxProducts`}>
      <Grid item xs={12} md={8} className={classes.gridContainer}>
        <Typography variant="h4">Séléctionnez votre offre :</Typography>
      </Grid>
      <Grid container item xs={12} md={8}>
        {renderProductsCard()}
      </Grid>
      {renderCartDialog()}
    </Grid>
  )
}

GridProductsCard.propTypes = {
  products: PropTypes.array.isRequired,
}

export default React.memo(GridProductsCard)
