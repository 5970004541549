import React from "react"
import { createContext } from "react"
import { useFetch } from "../hooks/useFetch"
import ajax from "../Helpers/ajax"
import { Loading } from "../Components/Loading/Loading"

const StoreContext = createContext({})

const StoreProvider = ({ children }) => {
  const { data, loading } = useFetch(ajax.getStore())
  const store = data?.data

  return (
    <>
      {loading && <Loading isHome={true} />}

      {!loading && (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
      )}
    </>
  )
}

export { StoreContext, StoreProvider }
